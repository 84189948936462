import { useEffect, useState } from 'react';

import { Outlet } from "react-router-dom"
import { Layout } from 'antd';
import { Header } from 'antd/es/layout/layout';


import TrackerHeader from "./TrackerHeader"
import SideBar from './SideBar';

import AuthService from "../../services/auth.service";


const { Sider, Content, Footer } = Layout;

const TrackerLayout = () => {

    const [currentUser, setCurrentUser] = useState(undefined);
    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
    }, []);


    return (

        <Layout className='layout'>
            <Header style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <TrackerHeader />
            </Header>
            <Layout>
                {currentUser && (
                    <Sider collapsible={true} trigger={null} >
                        <SideBar />
                    </Sider>
                )}
                <Content>
                    <Outlet />
                </Content>
            </Layout>

            <Footer style={{
                borderTop: '1px solid #e8e8e8',
                position: 'fixed',
                padding: '2vh 2vh',
                left: 0,
                bottom: 0,
                width: '100%',
                backgroundColor: 'white',
                textAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
            }}>
                Titanium Software @ {new Date().getFullYear()}
            </Footer>

        </Layout>
    );
}

export default TrackerLayout;