import { Alert, Form, Input, InputNumber, Modal, Select, Table } from "antd";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import errorService from "../../../services/error.service";
import eventBus from "../../../common/EventBus";
import ProductService from "../../../services/product.service";

import BuyService from "../../../services/buy.service";

import ProviderService from "../../../services/provider.service";
import Tracker from "../../../common/Constants";
import TrackerMessages from "../../../common/messages";
import StoreService from "../../../services/store.service";

const formItemLayout = { labelCol: { span: 40 } };

const BuyForm = () => {


    let navigate = useNavigate();
    const location = useLocation();
    const toIncidentsLocation = location.state?.from?.pathname || "/buys";

    const params = useParams();
    const [form] = Form.useForm();


    const [isOpen, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const [providers, setProviders] = useState([]);
    const [stores, setStores] = useState([]);

    const [tableData, setTableData] = useState([{}]);


    useEffect(() => {
        ProviderService.getAll().then(
            (response) => {
                const providers = response.data.map(provider => {
                    provider.label = provider.name;
                    provider.value = provider.id;
                    return provider;
                })
                console.log("The providers", providers)
                setProviders(providers);
            },
            (error) => { }
        );

        StoreService.getAll().then(
            (response) => {
                const stores = response.data.map(provider => {
                    provider.label = provider.name;
                    provider.value = provider.id;
                    return provider;
                })
                console.log("The stores", stores)
                setStores(stores);
            },
            (error) => { }
        );

    }, []);

    const onInputChange = (fieldName, index) => (e) => {
        console.log("The event is ", e)
        const newData = [...tableData];
        newData[index][fieldName] = getValue(fieldName, e);
        setTotal(newData, index);
        setTableData(newData);
    };

    const getValue = (fieldName, val) => {
        if (fieldName === "name") {
            return val.target.value;
        } else {
            return Number(val)
        }
    }

    const setTotal = (data, index) => {
        // Set total
        data[index]["total"] = Number(
            data[index]["quantity"] * data[index]["cost"]
        );
    };
    const moveNextFieldFocus = (fieldName, index) => {
        const nextfield = document.querySelector(`input[name=${fieldName}-${index}]`);
        if (nextfield !== null) {
            nextfield.focus();
        }
    }
    const onCodeChange = (e, record, index) => {
        const code = e.target.value;
        const newData = [...tableData];
        ProductService.findByCode(code).then(
            (response) => {
                if (response.data) {
                    record = response.data
                    record.quantity=0;
                    record.cost=0;
                    newData[index] = record;
                    setTableData(newData);
                    moveNextFieldFocus("quantity", index );
                }
                console.log("response", record)
            },
            (error) => {
                if (error.response.status === 404) {
                    record.isNew = true;
                    record.barCode = code;
                    newData[index] = record;
                    setTableData(newData);
                    moveNextFieldFocus("name", index);                    
                }
            }
        );
    }

    const columns = [
        {
            title: 'Codigo',
            dataIndex: 'barCode',
            key: 'barCode',
            render: (text, record, index) => (
                <Input name={`code-${index}`} value={text} onKeyUp={(e) => {                    
                    if (e.keyCode === 13) {
                        onCodeChange(e, record, index)
                    }
                }} />
            )
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
                if (record.isNew) {
                    return <Input
                        name={`name-${index}`}
                        value={text}
                        onChange={onInputChange("name", index)}
                    />
                } else {
                    return record.name
                }
            }
        },
        {
            title: 'Cantidad',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record, index) => (
                <InputNumber
                    name={`quantity-${index}`}
                    value={text}
                    min={1}
                    onChange={onInputChange("quantity", index)} />
            )
        },
        {
            title: 'Costo',
            dataIndex: 'cost',
            key: 'cost',
            render: (text, record, index) => (
                <InputNumber
                    name={`cost-${index}`}
                    value={text}
                    min={1}
                    onChange={onInputChange("cost", index)}
                    onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                            console.log("changing ")
                            const newData = [...tableData];
                            newData.push([])
                            setTableData(newData)
                            moveNextFieldFocus("code", index + 1);
                        }
                    }}
                />
            )
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total'
        }
    ];




    const closeModal = () => {
        setOpen(false);
        navigate(toIncidentsLocation, { replace: true });
    }

    const handleSubmit = (buyData) => {

        setMessage("");
        setLoading(true);


        BuyService.save(buyData).then(
            (response) => {
                setOpen(false);
                navigate(toIncidentsLocation, { replace: true });
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
                setLoading(false);
            }
        )


    }

    const handleOk = () => {
        const buyData = {
            provider: { id: form.getFieldValue("provider") },
            store: { id: form.getFieldValue("store") },
            products: tableData
        }
        handleSubmit(buyData);
    }


    return (
        <>
            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                centered
                title={`Compra de productos`}
                width={'80%'}
            >
                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    layout="inline"
                    validateMessages={TrackerMessages.common}
                >
                    <Form.Item label='Proveedor' name='provider' rules={Tracker.Validation.requiredOnly} style={{ width: "250px" }}>
                        <Select options={providers} />
                    </Form.Item>
                    <Form.Item label='Almacen' name='store' rules={Tracker.Validation.requiredOnly} style={{ width: "250px" }}>
                        <Select options={stores} />
                    </Form.Item>
                </Form>
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    bordered
                />

                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Modal>
        </>
    )
}
export default BuyForm;