import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/operator`;

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getById = (operatorId) => {
  return axios.get(`${API_URL}/${operatorId}`, { headers: authHeader() });
};

const save = (operator) => {
  return axios.post(API_URL, operator, { headers: authHeader() });
};


const deleteOperator = (operatorId) => {
  return axios.delete(`${API_URL}/${operatorId}`, { headers: authHeader() });
};

const update = (operatorId, operator) => {
  return axios.put(`${API_URL}/${operatorId}`, operator, { headers: authHeader() });
};

const OperatorService = {
  getAll,  
  save,
  update,
  deleteOperator,
  getById
};

export default OperatorService;
