import { Col, Space, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { Overlay } from "./Overlay";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import imageUnassignedTire from "../../../img/unassigned-tire.png";
import imageAssignedTire from "../../../img/assigned-tire.png";

export const ModelItem = ({ row, col, item, assignedTires }) => {

    const [tire, isTire] = useState(false);
    const [tooltipContent, setTooltipContent] = useState("Tire not assigned");
    const [assignedTire, setAssignedTire] = useState(false);
    const [tireStyle, setTireStyle] = useState({});
    const [tooltipColor, setTooltipColor] = useState("#cb3d3de0");
    const [backgroundTire, setBackgroundTire] = useState(imageUnassignedTire);


    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: ItemTypes.TIRE,
            canDrop: () => true,
            drop: (item, monitor) => {
                console.log("onDropHandler", item, monitor);
                item.positionX = col;
                item.positionY = row;
                return item;
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }),
        }),
        [],
    )

    useEffect(() => {        
        if (item.itemName === "tire") {
            isTire(true)
            setAssignedTire(assignedTires.find(assignedTire => { return assignedTire.positionY === row && assignedTire.positionX === col }));

            if (assignedTire) {
                setTooltipColor("#f3e997f2")
                setBackgroundTire(imageAssignedTire)
                setTooltipContent(<span>{assignedTire.maker}</span>)
            }
            setTireStyle({
                backgroundImage: `url(${backgroundTire})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            });
        }

    }, [assignedTires, assignedTire, backgroundTire])


    return (
        <>
            {!tire && <Col key={`d-c${row}-${col}`} span={2} className={`model-item ${item.itemName}`} style={tireStyle} />}
            {tire && <Tooltip
                key={`tooltip-${row}-${col}`}
                color={tooltipColor}
                title={<div key={`model-item-div-${row}-${col}`} style={{ display: "flex" }}>
                    <Space key={`model-item-tooltip-space-${row}-${col}`} direction="vertical">
                        <span key={`model-item-tooltip-title-${row}-${col}`} style={{ backgroundColor: "white", color: "black" }}>Tire Information</span>
                        <span key={`model-item-tooltip-body-${row}-${col}`}>{tooltipContent}</span>
                    </Space>
                </div>}
            >
                <Col
                    ref={drop}
                    role="Space"
                    key={`d-cs${row}-${col}`}
                    span={2}
                    className={`model-item ${item.itemName}`}
                    style={tireStyle}
                >
                    {canDrop && <Overlay />}
                </Col>
            </Tooltip>
            }
        </>
    )

}