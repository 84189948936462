import React from 'react';
import { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import { Card, Button, Form, Input, Alert, Divider, Select, Space } from 'antd';


import logo from "../img/tracker-transparent.png"
import errorService from '../services/error.service';
import Tracker from '../common/Constants';
import { useEffect } from 'react';
import CountryService from '../services/country.service';
import RegistryService from '../services/registry.service';

const Registry = () => {

  let navigate = useNavigate();
  const location = useLocation();
  const toLocation = location.state?.from?.pathname || "/login";

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [countries, setCountries] = useState([]);
  const licenses =[{label:"Enterprise", value:"ENTERPRISE"},{label:"Personal", value:"PERSONAL"}]

  const [form] = Form.useForm();

  let isValid = true;

  useEffect(() => {
    CountryService.getAllCountries().then(
      (response) => {
        const countries = response.data;
        setCountries(countries);

      }, (error) => {
        console.log(error)
        setMessage(errorService.checkRequest(error));
      }
    );
  }, [])


  const handleLogin = (auth) => {
    setLoading(true);
    isValid = form.getFieldsError().some((item) => item.errors.length === 0);
    if (isValid) {
      RegistryService.registry(auth).then(
        (data) => {
          console.log(data);
          navigate(toLocation, { replace: true });
          window.location.reload();
        },
        (error) => {
          setMessage(errorService.checkRequest(error));
          setLoading(false);

        }
      );
    }
    setLoading(false);
  };


  return (
    <Card
      style={{ display: "flex", width: '90%', alignContent: 'center', alignItems: 'center', marginTop: "1%", marginBottom: "5%", overflow:"auto", height:'43em' }}
      bodyStyle={{ justifyContent: 'center' }}
      cover={
        <img
          alt="Tracker"
          src={logo}
          style={{ width: "100%", height: "100%" }}
        />
      }
    >
      
        <Form form={form} name="registry" labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }} style={{ maxWidth: 650 }}
          initialValues={{ remember: true }} onFinish={handleLogin}
          onFinishFailed={handleLogin} size='small'
        >
      
            <Divider>Company Information</Divider>
            <Form.Item
              label="Name"
              name="name"
              rules={Tracker.Validation.requiredOnly}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Licencia"
              name="license"
              rules={Tracker.Validation.requiredOnly}
            >
              <Select options={licenses} />
            </Form.Item>
            <Form.Item
              label="NIT"
              name="nit"
              rules={Tracker.Validation.requiredOnly}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Direccion"
              name="address"
              rules={Tracker.Validation.requiredOnly}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Pais"
              name="country"
              rules={Tracker.Validation.requiredOnly}
            >
              <Select options={countries} fieldNames={{ label: 'name', value: 'id' }} />
            </Form.Item>
      
      
            <Divider>User Information</Divider>
            <Form.Item
              label="Username"
              name="username"
              rules={Tracker.Validation.requiredOnly}        >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={Tracker.Validation.requiredOnly}        >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={Tracker.Validation.requiredOnly}        >
              <Input />
            </Form.Item>
            <Form.Item
              label="Documento"
              name="document"
              rules={Tracker.Validation.requiredOnly}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Telefono"
              name="cellphone"
              rules={Tracker.Validation.requiredOnly}        >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" disabled={!isValid}>
                Register
              </Button>
            </Form.Item>
      
        </Form>
      
      {message && (
        <Alert
          message="Request error"
          description={message}
          type="error"
          closable
        />
      )}
    </Card>

  );
};

export default Registry;
