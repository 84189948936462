import React, { useState, useEffect } from "react";

import VehicleService from "../../services/vehicle.service";
import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
import actionFactory from '../../common/ActionFactory'
import ButtonActionRow from "../../common/ButtonActionRow";
import { Space, Table, Tooltip } from "antd";

const Vehicles = () => {
  const [content, setContent] = useState([]);
  const [tableActions, setTableActions] = useState([]);


  useEffect(() => {

    setTableActions(AuthService.getActionByGroup("Bus", "table"));

    VehicleService.getAll().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        console.log("Error", error)
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const drawData = () => {
    if (!content || !Array.isArray(content) || content.length === 0) {
      <div>No content</div>
    } else {
      return content.map((vehicle, index) => {
        vehicle.key = "vhc-" + index
        return vehicle;
      })
    }
  }
  const columns = [
    { title: 'Placa', dataIndex: 'plate', key: 'plate' },
    { title: 'Interno', dataIndex: 'code', key: 'code' },
    { title: <Tooltip title="Incidentes reportados el ultimo mes">Incidentes</Tooltip>, dataIndex: 'issues', key: 'mileage' },
    { title: <Tooltip title="Recorrido del ultimo mes">Recorrido</Tooltip>, dataIndex: 'mileage', key: 'mileage' },
    { title: <Tooltip title="Consumo del ultimo mes">Consumo</Tooltip>, dataIndex: 'oil', key: 'oil' },
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <Space size="middle">
          {
            tableActions.map((action, index) => {
              return actionFactory.getAction(action, { id: record.id })
            })
          }
        </Space>
      ),
    }
  ]

  return (
    <>
      <ButtonActionRow group="Bus" />

      <Table columns={columns} dataSource={drawData()} />
    </>
  );
};

export default Vehicles;
