const Tracker={

    Time:{
        DateFormat:'YYYY-MM-DD'
    },
    Validation:{
        requiredOnly:[
            {
                required: true
            }
        ]
    },
    backend:{
        host:process.env.REACT_APP_BACKEND_HOST
    }


}


export default Tracker;