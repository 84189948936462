import { Card, Carousel, Image } from "antd";
import React from "react";
import profileImage from "../../img/home/my_profile.svg"
import womanImage from "../../img/home/manager-woman.svg"


const UserInfo = () => {

    const contentStyle = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    }

    return (
        <Card
            title="Manejo de usuario"
            cover={
                <Carousel autoplay>
                    <div>
                        <h3 style={contentStyle}>
                            <Image src={profileImage}></Image>
                        </h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>
                            <Image src={womanImage}></Image>
                        </h3>
                    </div>
                </Carousel>}            
            style={{
                width: 300,
            }}>
            <p>Tracker te permite manejar Usuarios y privilegios de una manera simple</p>
            <p>Crea roles con privilegios especificos y asigna roles a cada usuario.</p>
            <p>Quieres personalizar los privilegios de un usuario? puedes hacerlo sin problemas</p>
        </Card>
    );
}

export default UserInfo;