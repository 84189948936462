import React, { useState, useEffect } from "react";


import VehicleTypeService from "../../../services/vehicle-type.service";
import AuthService from "../../../services/auth.service";
import EventBus from "../../../common/EventBus";
import actionFactory from '../../../common/ActionFactory'
import ButtonActionRow from "../../../common/ButtonActionRow";
import { Badge, Space, Table } from "antd";
import { ClockCircleOutlined } from '@ant-design/icons';

const VehicleTypes = () => {
  const [content, setContent] = useState([]);
  const [tableActions, setTableActions] = useState([]);
  

  useEffect(() => {

    setTableActions(AuthService.getActionByGroup("VehicleType", "table"));
  
    VehicleTypeService.getAll().then(
      (response) => {
        console.log(response)
        setContent(response.data);
      },
      (error) => {
        console.log("Error", error)
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columns = [
    { title: 'Type', dataIndex: 'name', key: 'name' },
    { title: 'Estado', dataIndex: 'active', key: 'active', render: (e, record) => (< Badge text={record.active} color="#52c41a" size="medium" count={
      record.active ? (
        <ClockCircleOutlined style={{color: '#f5222d'}}/>
      ) : (
        <ClockCircleOutlined style={{color: 'red'}}/>
      )
    }/>) },
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <Space size="middle">
          {
            tableActions.map((action, index) => {
              return actionFactory.getAction(action, { id: record.id })
            })
          }
        </Space>
      ),
    }
  ]

  const drawData = () => {
    if (!content || !Array.isArray(content) || content.length === 0) {
      <div>No content</div>
    } else {
      return content
    }
  }

  return (
    <>
      <ButtonActionRow group="VehicleType"/>
      <Table columns={columns} dataSource={drawData()} />
    </>
  );
};

export default VehicleTypes;
