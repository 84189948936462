import { useEffect, useState } from 'react';

import { Link, useNavigate } from "react-router-dom";
import { Avatar, Badge, Menu, notification } from 'antd'

import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
import logo from "../../img/tracker-150x190.png"
import { UserOutlined, NotificationTwoTone } from '@ant-design/icons';
import UserService from '../../services/user.service';

const TrackerHeader = () => {
    const [userMenu, setUserMenu] = useState([{ key: 1, label: <Link to='/login'>Login</Link> }]);
    const [countNotifications, setCountNotifications] = useState(0);
    const [notifications, setNotifications] = useState([]);


    let navigate = useNavigate();

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            UserService.getNotifications(user.id).then((response) => {
                setCountNotifications(response.data.count);
                setNotifications(response.data.notifications);
                notifications.map(notification => {
                    notification.key = `not-${notification.id}`;
                    notification.label = <Link to={`/notifications?id=${notification.id}`}>{notification.title}</Link>
                    return notification;
                })
                setUserMenu([{
                    key: 1,
                    label: <Badge count={countNotifications}><Avatar icon={<NotificationTwoTone twoToneColor='red' />} /></Badge>,
                    children: notifications
                }, {
                    key: 2,
                    label: <Avatar icon={<UserOutlined />}> {user.username.charAt(0)}</Avatar>,
                    children: [
                        {
                            key: 'p-2',
                            label: <Link to='/profile'>Perfil</Link>
                        },
                        {
                            key: 'p-1',
                            label: 'Salir',
                            onClick: logOut
                        }]
                }]);
            }).catch((error) => {
                console.log("Error loading notifications", error)
            });

        }

        EventBus.on("logout", () => {
            logOut();
        });

        return () => {
            EventBus.remove("logout");
        };
    }, []);


    const logOut = () => {
        AuthService.logout();
        navigate('/login', { replace: true });
        window.location.reload();
    };

    return (
        <>
            <div>
                <Link to={"/"}>
                    <img
                        alt=""
                        src={logo}
                        width="50"
                        height="50"
                        className="d-inline-block align-top"
                    />
                </Link>
            </div>
            <div style={{ width: '100%' }}>
                <Menu
                    style={{ justifyContent: 'flex-end' }}
                    theme="dark"
                    mode="horizontal"
                    items={userMenu}
                />
            </div>
        </>
    );
}

export default TrackerHeader;