import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import { Alert, DatePicker, Form, Modal, Popconfirm, Select, Table, TimePicker } from "antd";

import errorService from "../../services/error.service";
import TrackerMessages from "../../common/messages";
import Tracker from "../../common/Constants"
import DestinationService from "../../services/destination.service";
import VehicleService from "../../services/vehicle.service";
import JourneyService from "../../services/journey.service";
import dayjs from "dayjs";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const JourneyForm = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const toJourneysLocation = location.state?.from?.pathname || "/journeys";

    const params = useParams();
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [operators, setOperators] = useState([]);
    const [notDoneJourneys, setNotDoneJourneys] = useState([]);


    const [isOpen, setOpen] = useState(true);
    const [isOpenCompleteJourneyPopup, setIsOpenCompleteJourneyPopup] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {

        DestinationService.getAll().then(
            (response) => {
                setDestinationOptions(response.data.map(destination => { return { label: destination.name, value: destination.id } }))
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );

        VehicleService.getAll().then(
            (response) => {
                setVehicleOptions(response.data.map(vehicle => { return { label: vehicle.code, value: vehicle.id } }))
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );

        if (params.id) {
            setTitle('Edicion');
            setEditMode(true);
            JourneyService.getById(params.id).then(
                (response) => {
                    const journey = response.data;
                    journey.travelDate = dayjs(journey.travelDate);
                    const time = journey.travelTime.split(":");
                    journey.travelTime = dayjs().hour(time[0]).second(time[1]);
                    form.setFieldsValue(journey);

                    JourneyService.getAssigned(journey.vehicleId).then(
                        (response) => {
                            setOperators(response.data);
                        },
                        (error) => {
                            setMessage(errorService.checkRequest(error));
                            setLoading(false);
                        }
                    )
                }, (error) => {
                    console.log(error)
                    setMessage(errorService.checkRequest(error));
                }
            );

        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toJourneysLocation, { replace: true });
    }

    const handleSubmit = (destinationData) => {

        setMessage("");
        setLoading(true);


        if (editMode) {
            JourneyService.update(params.id, destinationData).then(
                (response) => {
                    setOpen(false);
                    navigate(toJourneysLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {
            JourneyService.save(destinationData).then(
                (response) => {
                    setOpen(false);
                    navigate(toJourneysLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                values.travelDate = dayjs(values.travelDate).format("YYYY-MM-DD")
                values.travelTime = dayjs(values.travelTime).format("HH:mm")
                console.log("values", values)
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const disabledDate = (submissionValue) => {
        if (!submissionValue) {
            return false;
        }
        return (submissionValue.valueOf() < Date.now()) || (submissionValue.valueOf() >= dayjs().add(1, 'month'));
    }

    const getOperators = (vehicleId) => {
        if (params.id) {
            JourneyService.getAssigned(vehicleId).then(
                (response) => {
                    setOperators(response.data);
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            );
        } else {
            VehicleService.getAssigned(vehicleId).then(
                (response) => {
                    console.log("Operators",response.data)
                    setOperators(response.data);
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            );
            const destination = form.getFieldValue("route");
            JourneyService.getJourneyByBusIdAndDestinationId(vehicleId, destination.id).then(
                (response) => {
                    const journey = response.data;
                    console.log("journeys not doen ", journey)
                    if (journey && Array.isArray(journey) && journey.length > 0) {
                        setNotDoneJourneys(journey)
                        //setIsOpenCompleteJourneyPopup(true)
                    }
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        }
    }
    const columns = [
        { dataIndex: 'firstname', title: 'Nombre' },
        { dataIndex: 'lastname', title: 'Apellidos' },
        { dataIndex: 'driverLicense', title: 'Licencia' },
        { dataIndex: 'category', title: 'Categoria' }
    ]


    const confirmCompleteJourney = () => {
        const journeysStatus = notDoneJourneys.map(journey => {
            return { travelId: journey.id, status: "DONE", vehicleId:journey.vehicleId };
        });
        JourneyService.completeJourneys(journeysStatus).then(
            (response) => {

                const success = response.data;
                if (success) {
                    setIsOpenCompleteJourneyPopup(true)
                }
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
                setLoading(false);
            }
        )
        setIsOpenCompleteJourneyPopup(false)
    }
    const cancelCompleteJourney = () => { setIsOpenCompleteJourneyPopup(false) }

    return (
        <Modal
            open={isOpen}
            onCancel={closeModal}
            onOk={handleOk}
            okButtonProps={{ disabled: loading }}
            title={`${title} de Viajes`}
        >
            <Form
                {...formItemLayout}
                form={form}
                labelAlign='left'
                validateMessages={TrackerMessages.common}
            >
                <Form.Item label='Fecha' name='travelDate'
                    initialValue={dayjs()} required rules={[
                        {
                            required: true
                        }
                    ]}>
                    <DatePicker format={"YYYY-MM-DD"}
                        disabledDate={disabledDate} />
                </Form.Item>
                <Form.Item label='Hora' name='travelTime'
                    initialValue={dayjs(`${new Date().getHours()}:00`, 'HH:mm')}
                    required rules={[
                        {
                            required: true
                        }
                    ]}>
                    <TimePicker format="HH:mm" minuteStep={10} />

                </Form.Item>
                <Form.Item label='Destino' name={['route', 'id']} required rules={[
                    {
                        required: true
                    }
                ]}>
                    <Select options={destinationOptions} />
                </Form.Item>
                <Form.Item label='Vehiculo' name='vehicleId' tooltip={TrackerMessages.journey.vehicle.tooltip} required rules={Tracker.Validation.requiredOnly}>
                    <Select options={vehicleOptions} onChange={getOperators} />
                </Form.Item>
                <Table columns={columns} dataSource={operators} size="small" />
                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Form>
            <Popconfirm
                title="Complete Journeys"
                description="There are some Incomplete Journes with the selected Vehicle, would you like to mark those as completed?"
                open={isOpenCompleteJourneyPopup}
                //onOpenChange={handleOpenChange}
                onConfirm={confirmCompleteJourney}
                onCancel={cancelCompleteJourney}
                okText="Yes"
                cancelText="No"
            ></Popconfirm>
        </Modal>
    )


}
export default JourneyForm;