import React, { useEffect, useState } from "react";
import useDataTable from "../../../hooks/useDataTable";
import eventBus from "../../../common/EventBus";
import ButtonActionRow from "../../../common/ButtonActionRow";
import errorService from "../../../services/error.service";
import AuthService from "../../../services/auth.service";
import BuyService from "../../../services/buy.service";


const Buys = () => {
  const [message, setMessage] = useState("")
  const handleDelete = (row) => {
    BuyService.delete(row.id).then(
      (response) => {
        reloadData();
      }
    ).catch((error) => { })
  }

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Proveedor',
      dataIndex: ['provider','name'],
      key: 'provider',
    },
    {
      title: 'Total',
      dataIndex: 'totalCost',
      key: 'totalCost'
    }
  ];
  const [data, setData] = useState([]);
  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    resetPagination,
  } = useDataTable({
    columns,
    dataSource: data,
    actions: AuthService.getActionByGroup("Buy", "table"),
    handleSingleDelete: handleDelete
  });
  useEffect(() => {
    reloadData();
  }, []);

  const reloadData=()=>{
    BuyService.getAll()
      .then((response) => {
        console.log("Las compras son", response.data)
        setData({ content: response.data, totalElements: response.data.lenght });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
        setMessage(errorService.checkRequest(error));
      })
  }
  return (
    <>
      <ButtonActionRow group="Buy" />
      <DataTable />
    </>
  );
};

export default Buys;
