import IconAction from "./IconAction"
import ButtonAction from "./ButonAction"
import editUser from '../img/user_edit_50x50.png'
import saveUser from '../img/user_save_50x50.png'
import deleteUser from '../img/user_delete_50x50.png'
import changePassword from '../img/change_password.png'
import userActions from '../img/user_actions.png'
import trackerIcon from '../img/tracker-150x190.png'
import addBus from '../img/add_vehicle.png'
import editBus from '../img/edit_vehicle.png'
import deleteBus from '../img/delete_vehicle.png'
import vehicleInfo from '../img/vehicle_info.png'
import mainteinance from '../img/mainteinance.png'
import oil from '../img/oil.png'
import operator from '../img/taxi_driver.png'
import operatorEdit from '../img/driver_edit.png'
import operatorDelete from '../img/driver_delete.png'
import journey from '../img/journey.png'
import journeyEdit from '../img/journey_edit.png'
import journeyDelete from '../img/journey_delete.png'
import DefaultIconAction from "./DefaultIconAction"

const getAction = (action, params) => {
    let type = -1;
    if (action) {
        type = action.groupType;
    }
    let performAction = action.function;
    if (params && params.id) {
        if (performAction.includes(":id")) {
            performAction = performAction.replace(":id", params.id)
        } else {
            performAction += `/${params.id}`
        }
    }

    if (params && params.parentId) {
        performAction += `?parentId=${params.parentId}&parentName=${params.parentName}`
    }

    switch (type) {
        case 'button': return <ButtonAction img={getIcon(action.name)} alt={action.description} action={performAction} text={action.displayName} key={`action-${action.actionId}`} />
        case 'table':
            let icon = getIcon(action.name);
            if (icon == null) {
                if (action.name.indexOf("EDIT") !== -1) {
                    return <DefaultIconAction img={icon} type="edit" alt={action.displayName} action={performAction} key={`action-${action.actionId}`} />
                }

                if (action.name.indexOf("DELETE") !== -1) {
                    return <DefaultIconAction img={icon} type="delete" alt={action.displayName} action={performAction} key={`action-${action.actionId}`} />
                }
                icon = trackerIcon;
            }

            return <IconAction img={icon} alt={action.displayName} action={performAction} key={`action-${action.actionId}`} />
        default: return <div key={`action-${action.actionId}`} />
    }
}

const actionFactory = {
    getAction
}

const getIcon = (actionName) => {

    switch (actionName) {
        case "ROLE_ADD_USER": return saveUser
        case "ROLE_EDIT_USER": return editUser
        case "ROLE_DELETE_USER": return deleteUser
        case "ROLE_CHANGE_PASSWORD": return changePassword
        case "ROLE_UPDATE_ACTIONS": return userActions
        case "ROLE_ADD_BUS": return addBus;
        case "ROLE_EDIT_BUS": return editBus
        case "ROLE_DELETE_BUS": return deleteBus
        case "ROLE_ADD_MAINTEINANCE": return mainteinance
        case "ROLE_ADD_GASOIL": return oil
        case "ROLE_VIEW_DETAILS": return vehicleInfo
        case "ROLE_EDIT_OPERATORS": return operator
        case "ROLE_ADD_OPERATOR": return operator
        case "ROLE_EDIT_OPERATOR": return operatorEdit
        case "ROLE_DELETE_OPERATOR": return operatorDelete
        case "ROLE_ADD_JOURNEY": return journey
        case "ROLE_EDIT_JOURNEY": return journeyEdit
        case "ROLE_DELETE_JOURNEY": return journeyDelete
        case "ROLE_JOURNEY_OPERATORS": return operator
        default: return null;
    }
}

export default actionFactory;