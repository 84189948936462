import React, { useState, useEffect } from "react";

import OperatorService from "../../../services/operator.service";
import AuthService from "../../../services/auth.service";
import EventBus from "../../../common/EventBus";
import actionFactory from '../../../common/ActionFactory'
import ButtonActionRow from "../../../common/ButtonActionRow";
import { Badge, Space, Table } from "antd";
import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const Operators = () => {
  const [content, setContent] = useState([]);
  const [tableActions, setTableActions] = useState([]);

  useEffect(() => {

    setTableActions(AuthService.getActionByGroup("Operator", "table"));

    OperatorService.getAll().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        console.log("Error", error)
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columns = [
    { title: 'Nombre', dataIndex: 'firstname', key: 'firstname' },
    { title: 'Apellidos', dataIndex: 'lastname', key: 'lastname' },
    { title: 'Licencia', dataIndex: 'driverLicense', key: 'driverLicense' },
    { title: 'Telefono', dataIndex: 'phoneNumber', key: 'phoneNumber' },
    { title: 'Referencia', dataIndex: 'reference', key: 'reference' },
    { title: 'Telefono', dataIndex: 'referencePhone', key: 'referencePhone' },
    {
      title: 'Estado', dataIndex: 'active', key: 'active',
      render: (e, record) => (
        <Badge text={record.active} count={
          record.active ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <ExclamationCircleOutlined style={{ color: 'red' }} />
          )
        } />)
    },
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <Space size="middle">
          {
            tableActions.map((action, index) => {
              return actionFactory.getAction(action, { id: record.id })
            })
          }
        </Space>
      ),
    }
  ]

  const drawData = () => {
    if (!content || !Array.isArray(content) || content.length === 0) {
      <div>No content</div>
    } else {
      return content
    }
  }

  return (
    <>
      <ButtonActionRow group="Operator" />

      <Table columns={columns} dataSource={drawData()} />
    </>
  );
};

export default Operators;
