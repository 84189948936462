import { DragPreviewImage, useDrag } from "react-dnd";
import tire from "../../../img/Car-Tire.svg";
import { ItemTypes } from "./ItemTypes";
import { Avatar, List } from "antd";

export const DraggableTireItem = ({item, index, onDragHandler}) => {

    const tireStyle = {
        fontSize: 40,
        fontWeight: 'bold',
        cursor: 'move',
      }

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: ItemTypes.TIRE,
            item:item,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
            end:(item, monitor)=>{
                onDragHandler(item, monitor)
            }
        }),
        [],
    )
    return (
        <>
            <DragPreviewImage connect={preview} src={tire} />
            <List.Item 
            key={`tire-list-${index}`}
            ref={drag} 
            style={{
                ...tireStyle,
                opacity: isDragging ? 0.5 : 1,
              }}
            >
                <List.Item.Meta
                  avatar={<Avatar src={tire} size={64} shape="square"/>}
                  title={item.code}
                  description={`${item.high}/${item.diameter}/${item.broad}`}
                />
              </List.Item>
        </>
    )
}