import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import VehicleTypeService from "../../../services/vehicle-type.service";
import { Alert, Form, Input, Modal, Switch } from "antd";
import Messages from "../../../common/messages";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const VehicleTypeForm = () => {

    let navigate = useNavigate();

    const params = useParams();
    const [name, setName] = useState('');

    const [isOpen, setOpen] = useState(true);
    const [form] = Form.useForm();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {

        if (params.id) {

            setTitle('Edicion');
            setEditMode(true);
            VehicleTypeService.getType(params.id).then(
                (response) => {
                    const busType = response.data;
                    form.setFieldsValue(busType)
                }, (error) => {
                    setMessage(error)
                }
            );
        }
    });


    const closeModal = () => {
        setOpen(false);
        navigate("/vehicle-type", { replace: true });
    }


    const handleSubmit = (typeData) => {
        setMessage("");
        setLoading(true);

        if (editMode) {
            VehicleTypeService.update(params.id, typeData).then(
                (response) => {
                    setOpen(false);
                    navigate("/buses", { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        } else {
            VehicleTypeService.save(typeData).then(
                (response) => {
                    setOpen(false);
                    navigate("/vehicle-type", { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        };
    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                console.log("the values", values)
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    return (

        <Modal
            open={isOpen}
            onCancel={closeModal}
            onOk={handleOk}
            okButtonProps={{ disabled: loading }}
            title={`${title} de Tipo de Vehiculo`}
        >

            <Form
                {...formItemLayout}
                size='medium'
                form={form}
                labelAlign='left'
                validateMessages={Messages.common}
            >
                <Form.Item labe='Tipo' name='name' required >
                    <Input placeholder="Vehicle Type" />
                </Form.Item>
                <Form.Item label='Estado' name='active' required >
                    <Switch />
                </Form.Item>


                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Form>
        </Modal>
    )
}
export default VehicleTypeForm;