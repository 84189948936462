import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/state`;

const updateState = (stateId, state) => {
  return axios.put(`${API_URL}/${stateId}`, state, { headers: authHeader() });
};

const getState = (stateId) => {
  return axios.get(API_URL + "/" + stateId, { headers: authHeader() });
};

const save = (state) => {
  return axios.post(API_URL, state, { headers: authHeader() });
};

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const StateService = {
  updateState,
  getState,
  save,
  getAll

};

export default StateService;
