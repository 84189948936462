import React, { useState, useEffect } from "react";
import ButtonActionRow from "../../../common/ButtonActionRow";
import useDataTable from "../../../hooks/useDataTable";


const WorkOrders = () => {

  useEffect(() => {

  }, []);

  const columns = [
    {
      title: 'Vechiculo',
      dataIndex: 'code',
      key: 'code',
      render: vehicle => <a>{vehicle.code}</a>,
    },
    {
      title: 'Inicio',
      dataIndex: 'start_date',
      key: 'start_date',
    },
    {
      title: 'fin',
      dataIndex: 'end_date',
      key: 'end_date',
    },
    {
      title: 'Mecanico',
      dataIndex: 'responsible',
      key: 'responsible',
    },
    {
      title: 'Costo',
      key: 'cost',
      dataIndex: 'cost'
    },
  ];
  const [data, setData] = useState([]);
  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    resetPagination,
  } = useDataTable({
    columns,
    dataSource: data,
    updateEntityPath: 'update-product',
  });
  return (
    <>
      <ButtonActionRow group="WorkOrder" />

      <DataTable />
    </>
  );
};

export default WorkOrders;
