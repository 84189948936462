import React, { useEffect, useState } from "react";

import useDataTable from "../../../hooks/useDataTable";
import eventBus from "../../../common/EventBus";
import ButtonActionRow from "../../../common/ButtonActionRow";
import errorService from "../../../services/error.service";
import AuthService from "../../../services/auth.service";
import ProductService from "../../../services/product.service";


const Products = () => {
  const [message, setMessage] = useState("")
  const handleDelete = (row) => {
    ProductService.deleteIncident(row.id).then(
      (response) => {

      }
    ).catch((error) => { })
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Almacen',
      dataIndex: 'store',
      key: 'store'
    },
    {
      title: 'Ubicacion',
      dataIndex: 'location',
      key: 'location',
    }
  ];
  const [data, setData] = useState([]);
  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    resetPagination,
  } = useDataTable({
    columns,
    dataSource: data,
    actions: AuthService.getActionByGroup("Product", "table"),
    handleSingleDelete: handleDelete
  });
  useEffect(() => {
    ProductService.getAll()
      .then((response) => {
        console.log("the data", response)
        setData({ content: response.data, totalElements: response.data.lenght });

      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
        setMessage(errorService.checkRequest(error));
      })
  }, []);
  return (
    <>
      <ButtonActionRow group="Product" />
      <DataTable />
    </>
  );
};

export default Products;
