import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom'


const DefaultIconAction = (props) => {
    const edit = props.type === "edit" ? true : false;
    return (
        <Tooltip key={props.id} title={props.alt}>
            <Link to={props.action} target="_self" rel="noreferrer">
                {edit && (<EditOutlined style={{ width: '25px', color: 'orange' }} />)}
                {!edit && (<DeleteOutlined style={{ width: '25px', color: 'red' }} />)}
            </Link>
        </Tooltip>
    )
}

export default DefaultIconAction;