import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import errorService from "../../../services/error.service";
import { Alert, DatePicker, Form, Input, Modal, Select } from "antd";
import Messages from "../../../common/messages";
import OperatorService from "../../../services/operator.service";
import moment from "moment/moment";
import dayjs from "dayjs";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const OperatorForm = () => {

    
  let navigate = useNavigate();
  const location = useLocation();
  const toOperatorsLocation = location.state?.from?.pathname || "/operators";

    const params = useParams();
    const [form] = Form.useForm();


    const [isOpen, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {

        if (params.id) {
            setTitle('Edicion');
            setEditMode(true);
            OperatorService.getById(params.id).then(
                (response) => {
                    const operator = response.data;
                    form.setFieldValue("expirationDate", moment(operator.expirationDate));
                    delete operator.expirationDate;
                    form.setFieldsValue(operator);
                }, (error) => {
                    console.log(error)
                    setMessage(errorService.checkRequest(error));
                }
            );
        } else {
            form.setFieldValue("category", "C")
        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toOperatorsLocation, { replace: true });
    }

    const handleSubmit = (operatorData) => {

        setMessage("");
        setLoading(true);


        if (editMode) {
            
            OperatorService.update(params.id, operatorData).then(
                (response) => {
                    setOpen(false);
                    navigate(toOperatorsLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {
            OperatorService.save(operatorData).then(
                (response) => {
                    setOpen(false);
                    navigate(toOperatorsLocation, { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                values.expirationDate = dayjs(values.expirationDate).format("YYYY-MM-DD")
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
            >
            </div>

            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                centered
                title={`${title} de Operadores`}
            >
                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    labelAlign='left'
                    validateMessages={Messages.common}
                >
                    <Form.Item label='Nombre' name='firstname' required rules={[
                        {
                            required: true
                        }
                    ]}>
                        <Input placeholder="Juan" />
                    </Form.Item>
                    <Form.Item label='Apellido' name='lastname' required rules={[
                        {
                            required: true
                        }
                    ]}>
                        <Input placeholder="Perez" />

                    </Form.Item>
                    <Form.Item label='Licencia' name='driverLicense' required rules={[
                        {
                            required: true
                        }
                    ]}>
                        <Input placeholder="125254587" />

                    </Form.Item>
                    <Form.Item label='Categoria' name='category' required rules={[
                        {
                            required: true
                        }
                    ]}>
                        <Select>
                            <Select.Option value="A">A</Select.Option>
                            <Select.Option value="B">B</Select.Option>
                            <Select.Option value="C">C</Select.Option>
                            <Select.Option value="X">Ayudante</Select.Option>
                        </Select>

                    </Form.Item>
                    <Form.Item label='Fecha de Exp.' name='expirationDate' required rules={[
                        {
                            required: true
                        }
                    ]}>
                        <DatePicker placeholder="2030-10-25" format={"YYYY-MM-DD"} />

                    </Form.Item>
                    <Form.Item label='Direccion' name='address' required rules={[
                        {
                            required: true
                        }
                    ]}>
                        <Input placeholder="Av Ayacucho no 100" />
                    </Form.Item>
                    <Form.Item label='Telefono' name='phoneNumber' required rules={[
                        { required: true }
                    ]}>
                        <Input placeholder="76512458" />
                    </Form.Item>
                    <Form.Item label='Referencia' name='reference' required rules={[
                        {
                            required: true
                        }
                    ]}>
                        <Input placeholder="Juana" />
                    </Form.Item>

                    <Form.Item label='Telefono' name='referencePhone' required rules={[
                        {
                            required: true
                        }
                    ]}>
                        <Input placeholder="76487877" />
                    </Form.Item>

                    {message && (
                        <Alert
                            message="Request error"
                            description={message}
                            type="error"
                            closable
                        />
                    )}
                </Form>
            </Modal>
        </>
    )
}
export default OperatorForm;