import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Alert, Form, Input, Modal } from 'antd';

import RoleService from "../../services/role.service";
import UserService from "../../services/user.service";
import { useEffect } from "react";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const ChangePassword = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const toLocation = location.state?.from?.pathname || "/users";

    const params = useParams();
    const [form] = Form.useForm();

    const [isOpen, setOpen] = useState(true);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");




    useEffect(() => {
        if (params.id) {
            UserService.getUser(params.id).then(
                (response) => {
                    const user = response.data;
                    form.setFieldsValue(user);
                }, (error) => { }
            );
        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate("/users", { replace: true });
    }



    const handleSubmit = (newPasswordValues) => {

        setMessage("");
        setLoading(true);

        UserService.changePassword(params.id, newPasswordValues).then(
            (response) => {
                setOpen(false);
                navigate("/users", { replace: true });
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setMessage(_content);
                setLoading(false);
            }
        );

    }
    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <Modal
            open={isOpen}
            onCancel={closeModal}
            onOk={handleOk}
            title={`Actualizar Password de Usuario`}
            okButtonProps={{ disabled: loading }}
        >
            <Form
                {...layout}
                size='medium'
                form={form}
                labelAlign='left'
            >

                <Form.Item label='New Password'
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item label='Repit Password'
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                {message && (
                    <Alert
                    message="Request error"
                    description={message}
                    type="error"
                    closable
                />
                )}
            </Form>
        </Modal>
    )
}
export default ChangePassword;