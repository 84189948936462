import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import dayjs from "dayjs";

import { Alert, DatePicker, Form, Input, Modal, Switch } from "antd";
import Messages from "../../../common/messages";
import Tracker from "../../../common/Constants";
import TireService from "../../../services/tire.service";
import errorService from "../../../services/error.service";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const TireForm = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const routeTo = location.state?.from?.pathname || "/tires";

    const params = useParams();
    

    const [isOpen, setOpen] = useState(true);
    const [form] = Form.useForm();
    
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {

        if (params.id) {

            setTitle('Edicion');
            setEditMode(true);
            TireService.findById(params.id).then(
                (response) => {
                    const tire = response.data;
                    tire.buyDate = dayjs(tire.buyDate);
                    form.setFieldsValue(tire)
                }, (error) => {
                    setMessage(errorService.checkRequest(error));
                }
            );
        }
    },[]);


    const closeModal = () => {
        setOpen(false);
        navigate(routeTo, { replace: true });
    }


    const handleSubmit = (tireData) => {
        setMessage("");
        setLoading(true);

        if (editMode) {
            TireService.update(params.id, tireData).then(
                (response) => {
                    setOpen(false);
                    navigate(routeTo, { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        } else {
            TireService.save(tireData).then(
                (response) => {
                    setOpen(false);
                    navigate(routeTo, { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        };
    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {                
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    return (

        <Modal
            open={isOpen}
            onCancel={closeModal}
            onOk={handleOk}
            okButtonProps={{ disabled: loading }}
            title={`${title} Llantas`}
        >

            <Form
                {...formItemLayout}
                size='medium'
                form={form}
                labelAlign='left'
                validateMessages={Messages.common}
            >
                <Form.Item label='Codigo' name='code' rules={Tracker.Validation.requiredOnly} >
                    <Input placeholder="UTQG Code" />
                </Form.Item>
                <Form.Item label='Fecha de compra' name='buyDate' rules={Tracker.Validation.requiredOnly}>
                    <DatePicker placeholder="2023-1-12" />
                </Form.Item>
                <Form.Item label='Ancho' name='broad' rules={Tracker.Validation.requiredOnly} >
                    <Input placeholder="75" addonAfter="mm"/>
                </Form.Item>
                <Form.Item label='Alto' name='high' rules={Tracker.Validation.requiredOnly} >
                    <Input placeholder="60"  addonAfter="%"/>
                </Form.Item>
                <Form.Item label='Diametro' name='diameter' rules={Tracker.Validation.requiredOnly} >
                    <Input placeholder="85"  addonAfter="inch"/>
                </Form.Item>
                <Form.Item label='Indice de Carga' name='loadIndex'  >
                    <Input placeholder="200"  addonAfter="psi"/>
                </Form.Item>
                <Form.Item label='Indice de Velocidad' name='velocityIndex'  >
                    <Input placeholder="60"  addonAfter="k/h"/>
                </Form.Item>
                <Form.Item label='Presion' name='pressure'  >
                    <Input placeholder="200"  addonAfter="psi"/>
                </Form.Item>
                <Form.Item label='Marca' name='maker'  >
                    <Input placeholder="GoodYear" />
                </Form.Item>
                <Form.Item label='Estado' name='active' rules={Tracker.Validation.requiredOnly} >
                    <Switch />
                </Form.Item>


                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Form>
        </Modal>
    )
}
export default TireForm;