import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import UserService from "../../services/user.service";
import { useEffect } from "react";

import { Alert, Checkbox, Collapse, Form, Modal, Switch } from "antd";
const { Panel } = Collapse;
const UpdateActions = () => {

    const [form] = Form.useForm();
    const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 12 } };

    let navigate = useNavigate();
    const location = useLocation();
    const toUserLocation = location.state?.from?.pathname || "/users";

    const params = useParams();
    const [actions, setActions] = useState([]);

    const [isOpen, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        console.log("params ", params);
        if (params.id) {
            console.log("entering edit mode");
            UserService.getActions(params.id).then(
                (response) => {
                    const actions = response.data;
                    console.log(actions)
                    setActions(actions.actions);
                }, (error) => { }
            );
        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toUserLocation, { replace: true });
    }

    const onChangeActionAssigned = (actionId, action) => {
        console.log("selected action", actionId, action);
        const aux = [...actions]
        aux.map(el => {
            if (el.actionId === actionId) {
                el.assigned = !action.assigned
            }
            return el;
        })

        setActions(aux)
    }

    const selectActions = (selectedActions, event) => {
        const aux = [...actions]
        
        aux.map(action => {
            const actionAux = selectedActions.find(selectedAction => action.actionId === selectedAction.actionId)
            if (actionAux) {
                actionAux.assigned = event;
                form.setFieldValue(actionAux.actionId, event);  
                return actionAux;
            }
            
            return action;
        })
        
        setActions(aux)
        
    }

    const drawAssigned = () => {
        let aux = [...actions]
        let cards = {};
        aux.forEach((card) => {
            if (!cards[card.group]) {
                cards[card.group] = []
            }
            cards[card.group].push(card);
        });

        return (
            <Collapse accordion defaultActiveKey={['accordion-Menu']} >
                {
                    Object.keys(cards).map((index) => {
                        const acs = cards[index]
                        return (
                            <Panel header={index} key={`accordion-${index}`} extra={<Checkbox label="Select All" onClick={(event) => { event.stopPropagation(); selectActions(acs, event.target.checked); }} />}>
                                {
                                    acs.map((action, i) => {
                                        return (
                                            <Form.Item
                                                label={action.displayName}
                                                name={`${action.actionId}`}
                                                key={`action-${action.actionId}`}
                                                initialValue={action.assigned}
                                                valuePropName="checked"
                                            >
                                                <Switch
                                                    onChange={() => onChangeActionAssigned(action.actionId, action)}
                                                />
                                            </Form.Item>
                                        )
                                    })
                                }
                            </Panel>
                        )
                    })
                }
            </Collapse >
        )
    }
    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    const handleSubmit = () => {
        console.log(actions);
        setMessage("");
        setLoading(true);
        const userData = { actions };

        UserService.updateActions(params.id, userData).then(
            (response) => {
                setOpen(false);
                navigate("/users", { replace: true });
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setMessage(_content);
                setLoading(false);
            }
        )


    }

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
            >
            </div>

            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                title={`Actualizar permisos`}
                okButtonProps={{ disabled: loading }}
            >
                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    labelAlign='left'
                >

                    {
                        drawAssigned()
                    }
                </Form>

                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Modal>
        </>
    )
}
export default UpdateActions;