import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Button } from "antd";
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

const ButtonAction = (props) => {
    let navigate = useNavigate();
    let route = useRef(props.action);
    const [hasImage, setHasImage] = useState(false)
    useEffect(() => {
        route.current += props.id ? "/" + props.id : "";
        setHasImage(props.img === null ? false : true);
    }, []);
    return (
        <Button
            type="primary"
            onClick={() => navigate(route.current)}
            icon={!hasImage ? <CheckCircleOutlined style={{ width: '25px', color: 'dark-green' }} />:""}
        >

            {hasImage && <img
                src={props.img}
                alt={props.alt}
                width="28px"
                height="28px"
                style={{ padding: "1px 1px 5px 1px" }}
            />
            }
            {props.text}
        </Button>
    )
}

export default ButtonAction;