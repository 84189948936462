import React, { useState, useEffect } from "react";

import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
import actionFactory from '../../common/ActionFactory'
import ButtonActionRow from "../../common/ButtonActionRow";
import { Badge, Space, Table } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import ProviderService from "../../services/provider.service";

const Providers = () => {
  const [providers, setProviders] = useState([]);
  const [tableActions, setTableActions] = useState([]);

  useEffect(() => {

    setTableActions(AuthService.getActionByGroup("Providers", "table"));

    ProviderService.getAll().then(
      (response) => {
        console.log(response)
        setProviders(response.data);
      },
      (error) => {
        //TODO: set error message        
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columns = [
    { title: 'Nombre', dataIndex: 'name', key: 'name' },
    { title: 'NIT', dataIndex: 'nit', key: 'nit' },
    { title: 'Direccion', dataIndex: 'address', key: 'address' },
    { title: 'Tipo', dataIndex: 'type', key: 'type' },    
    {
      title: 'Estado',
      dataIndex: 'active',
      key: 'active',
      render: (e, record) => (
        <Badge
          text={record.active}
          size="medium"
          count={
            record.active ? (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
              <ExclamationCircleOutlined style={{ color: 'red' }} />
            )
          } />)
    },
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <Space size="middle">
          {
            tableActions.map((action, index) => {
              return actionFactory.getAction(action, { id: record.id })
            })
          }
        </Space>
      ),
    }
  ]

  const drawData = () => {
    
      return providers.map(provider=>{provider.key=provider.id;return provider;})
    
  }

  return (
    <>
      <ButtonActionRow group="Providers" />

      <Table columns={columns} dataSource={drawData()} />
    </>
  );
};

export default Providers;
