import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Form, Input, Modal, Table } from "antd";



import PlanService from "../../../services/planTemplate.service";
import errorService from "../../../services/error.service";
import ServiceTemplateService from "../../../services/serviceTemplate.service";

import Messages from "../../../common/messages";
import Tracker from "../../../common/Constants";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const PlanForm = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const plansURL = location.state?.from?.pathname || "/plans";

    const params = useParams();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [allServices, setAllServices] = useState([]);
    const [assignedService, setAssignedService] = useState([]);


    const [isOpen, setOpen] = useState(true);
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    useEffect(() => {

        if (params.id) {
            PlanService.getPlan(params.id).then(
                (response) => {
                    form.setFieldValue("name",response.data.name)
                    setAssignedService(response.data.services);
                    setSelectedRowKeys(response.data.services.map(service => service.id));
                }, (error) => {
                    console.log(error)
                    setMessage(errorService.checkRequest(error));
                }

            );
        }
        ServiceTemplateService.getAll().then(
            (response) => {
                setAllServices(response.data.map(item => {
                    return Object.assign(item, assignedService.find(anotheritem => {
                        return item.id === anotheritem.id
                    }))
                })
                );
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(plansURL, { replace: true });
    }

    const handleSubmit = (planData) => {

        setMessage("");
        setLoading(true);


        if (params.id) {
            PlanService.update(params.id, planData).then(
                (response) => {
                    setOpen(false);
                    navigate(plansURL, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {
            PlanService.save(planData, selectedRowKeys).then(
                (response) => {
                    setOpen(false);
                    navigate(plansURL, { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                if(selectedRowKeys.length>0){
                    handleSubmit(values);
                }else{
                    setMessage("Por favor seleccion por lo menos un servicio");
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }


    const servicesColumns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
        },
        {
            title: 'Frequencia',
            dataIndex: 'recurrence_type',
        },
        {
            title: 'Tiempo',
            dataIndex: 'next_time',
        },
        {
            title: 'Kilometros',
            dataIndex: 'mileage'
        },
    ];

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            console.log('selectedRowKeys changed: ', newSelectedRowKeys);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
            >
            </div>

            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                title={`Plan de mantenimientos`}
            >

                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    labelAlign='left'
                    validateMessages={Messages.common}
                >
                    <Form.Item label='Nombre' name='name' required rules={Tracker.Validation.requiredOnly}>
                        <Input placeholder="plan de mantenimiento base" />
                    </Form.Item>
                    {/* aqui se debe agregar los items del nuevo plan de mantenimineto */}

                    <Table
                        rowKey={(record) => record.id}
                        rowSelection={rowSelection}
                        columns={servicesColumns}
                        dataSource={allServices}
                    />

                </Form>
                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Modal>
        </>
    )
}
export default PlanForm;