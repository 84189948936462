import { ControlOutlined, FunctionOutlined, ShopFilled, ToolFilled, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function MenuHelper() {
    
    return [
        {
            "key": "sider-1",
            "icon": <UserOutlined />,
            "label": "Usuarios",
            "type": "",
            "children": [
                {
                    "key": "ROLE_LIST_USERS",
                    "label": <Link to="/users">Listar Usuarios</Link>    
                },
                {
                    "key": "ROLE_LIST_ROLES",
                    "label": <Link to="/roles">Listar Roles</Link>
                }
            ]
        },
        {
            "key": "sider-2",
            "icon": <FunctionOutlined />,
            "label": "Vehiculos",
            "type": "",
            "children": [
                {
                    "key": "ROLE_LIST_BUSES",
                    "label": <Link to="/vehicles">Listar vehiculos</Link>
                },
                {
                    "key": "ROLE_LIST_VEHICLE_TYPE",
                    "label": <Link to="/vehicle-type">Listar Tipo de Vehiculos</Link>
                },
                {
                    "key": "ROLE_LIST_OPERATORS",
                    "label": <Link to="/operators">Listar Operadores</Link>
                },
                {
                    "key": "ROLE_LIST_JOURNEY",
                    "label": <Link to="/journeys">Listar Viajes</Link>
                },
                {
                    "key": "ROLE_LIST_ROUTES",
                    "label": <Link to="/destinations">Ver Destinos</Link>
                }
            ]
        },
        {
            "key": "sider-3",
            "icon": <ToolFilled />,
            "label": "Mantenimientos",
            "type": "",
            "children": [
                {
                    "key": "ROLE_LIST_SERVICE",
                    "label": <Link to="/services">Listar Servicios</Link>
                },
                {
                    "key": "ROLE_LIST_PLANS",
                    "label": <Link to="/plans">Listar Planes de mantenimiento</Link>,
                    "title": "Listar Planes de Mantenimiento"
                },
                {
                    "key": "ROLE_LIST_INCIDENTS",
                    "label": <Link to="/incidents">Listar Incidentes</Link>,
                    "title": "Listar Incidentes"
                },
                {
                    "key": "ROLE_LIST_WORK_ORDERS",
                    "label": <Link to="/work-order">Listar Orden de trabajos</Link>,
                    "title": "Listar Ordenes de trabajo"
                },
                {
                    "key": "ROLE_LIST_WORK",
                    "label": <Link to="/work">Listar Mantenimientos</Link>,
                    "title": "Listar Mantenimientos realizados"
                }
            ]
        },
        {
            "key": "sider-4",
            "icon": <ShopFilled />,
            "label": "Inventario",
            "type": "",
            "children": [
                {
                    "key": "ROLE_LIST_STORES",
                    "label": <Link to="/stores">Listar Almacenes</Link>
                },
                {
                    "key": "ROLE_LIST_PRODUCTS",
                    "label": <Link to="/products">Listar Productos</Link>,
                    "title": "Listar Repuestos"
                },
                {
                    "key": "ROLE_LIST_BUYS",
                    "label": <Link to="/buys">Listar Compras</Link>,
                    "title": "Listar Compras"
                }
            ]
        },
        {
            "key": "sider-5",
            "icon": <ControlOutlined />,
            "label": "Llantas",
            "type": "",
            "children": [
                {
                    "key": "ROLE_LIST_TIRES",
                    "label": <Link to="/tires">Listar Llantas</Link>
                },
                {
                    "key": "ROLE_ASSIGN_TIRE",
                    "label": <Link to="/assign-tire">Asignar Llantas</Link>
                },
            ]
        },
        {
            "key": "sider-6",
            "icon": <ControlOutlined />,
            "label": "Configuracion",
            "type": "",
            "children": [
                {
                    "key": "ROLE_LIST_COUNTRIES",
                    "label": <Link to="/countries">Listar Paises</Link>
                },
                {
                    "key": "ROLE_LIST_SERVICE_PROVIDERS",
                    "label": <Link to="/providers">Listar Proveedores</Link>
                },
                {
                    "key": "ROLE_LIST_COST",
                    "label": <Link to="/fuel-costs">Listar Tipo de Combustible</Link>
                }
            ]
        }
    ]

}