import { Card, Carousel, Image, List } from "antd";
import React from "react";
import carImage from "../../img/Vehicle1.png"
import busImage from "../../img/Bus.svg"
import truckImage from "../../img/truck.svg"
import trailerImage from "../../img/trailer.svg"
import { DollarOutlined } from "@ant-design/icons";
const VehicleInfo = () => {

    const contentStyle = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    }

    const beneficios = [
        {
            title: 'Gastos de un vehiculo'
        }, {
            title: 'Incidentes de un vehiculo',
        }, {
            title: 'Mantenimientos',
        }, {
            title: 'y mucho mas',
        }]
    return (
        <Card
            title="Manejo de Vehiculos"
            cover={
                <Carousel autoplay>
                    <div>
                        <h3 style={contentStyle}>
                            <Image src={carImage}></Image>
                        </h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>
                            <Image src={busImage}></Image>
                        </h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>
                            <Image src={truckImage}></Image>
                        </h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>
                            <Image src={trailerImage}></Image>
                        </h3>
                    </div>
                </Carousel>}            
            style={{
                width: 300,
            }}>
            <p>Registra tipos de Vehiculos</p>
            <p>Maneja toda la informacion de tus vehiculos.</p>
            <List
                itemLayout="horizontal"
                dataSource={beneficios}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<DollarOutlined />}
                            title={item.title}
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
}

export default VehicleInfo;