import React from 'react';
import { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import { Card, Button, Checkbox, Form, Input, Alert } from 'antd';

import AuthService from "../services/auth.service";
import logo from "../img/tracker-transparent.png"
import errorService from '../services/error.service';

const { Meta } = Card;

const Login = () => {

  let navigate = useNavigate();
  const location = useLocation();
  const toLocation = location.state?.from?.pathname || "/";

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [form] = Form.useForm();

  let isValid = true;


  const handleLogin = (auth) => {
    setLoading(true);
    isValid = form.getFieldsError().some((item) => item.errors.length === 0);
    if (isValid) {
      AuthService.login(auth).then(
        (data) => {
          console.log(data);
          navigate(toLocation, { replace: true });
          window.location.reload();
        },
        (error) => {
          setMessage(errorService.checkRequest(error));
          setLoading(false);
          
        }
      );
    }
    setLoading(false);
  };

  const redirect = () =>{
    navigate('/register');
  }

  return (
    <Card
      className='login-form'
      bodyStyle={{ justifyContent: 'center' }}
      cover={
        <img
          alt="Tracker"
          src={logo}
          style={{ width: "100%", height: "100%" }}
        />
      }
    >
      <Meta
        title="Login"
        description="Please fill your user information to login."
      />
      <br />
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={handleLogin}
        onFinishFailed={handleLogin}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" disabled={!isValid}>
            Login
          </Button>
          <Button type="primary" htmlType="button" onClick={redirect}>
            Register
          </Button>
        </Form.Item>
      </Form>

      {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
    </Card>

  );
};

export default Login;
