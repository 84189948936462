import React, { useState, useEffect } from "react";
import ButtonActionRow from "../../../common/ButtonActionRow";
import useDataTable from "../../../hooks/useDataTable";
import IncidentService from "../../../services/incident.service";
import eventBus from "../../../common/EventBus";
import errorService from "../../../services/error.service";
import { Image } from "antd";
import AuthService from "../../../services/auth.service";


const Incidents = () => {
  const [message, setMessage] = useState("");

  const handleDelete= (row)=>{
    IncidentService.deleteIncident(row.id).then(
      (response) =>{

      }
    ).catch((error)=>{})
  }

  const columns = [
    {
      title: 'Inicio',
      dataIndex: 'reportedDate',
      key: 'reportedDate',
    },
    {
      title: 'fin',
      dataIndex: 'dueDate',
      key: 'dueDate',
    },
    {
      title: 'Vechiculo',
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: vehicle => <a>{vehicle.code}</a>,
    },
    {
      title: 'Detalle',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Estado',
      key: 'status',
      dataIndex: 'status'
    },
    {
      title: 'Imagen',
      key: 'imagePath',
      dataIndex: 'imagePath',
      render: imagePath => <Image alt={imagePath} src={`${imagePath}`} with="40px" height="30px" />
    },
    {
      title: 'Reporto',
      key: 'reporter',
      dataIndex: 'reporter',
      render: reporter => <a>{`${reporter.name + '-' + reporter.lastname}`}</a>,
    }
  ];
  const [data, setData] = useState([]);
  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    resetPagination,
  } = useDataTable({
    columns,
    dataSource: data,
    actions: AuthService.getActionByGroup("Incident", "table"),
    handleSingleDelete:handleDelete
  });
  useEffect(() => {
    IncidentService.getAll()
      .then((response) => {
        console.log("the data", response)
        setData({ content: response.data, totalElements: response.data.lenght });

      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
        setMessage(errorService.checkRequest(error));
      })
  }, []);
  return (
    <>
      <ButtonActionRow group="Incident" />
      <DataTable />
    </>
  );
};

export default Incidents;
