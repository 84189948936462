import React, { useState, useEffect } from "react";


import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
import actionFactory from '../../common/ActionFactory'
import ButtonActionRow from "../../common/ButtonActionRow";
import { Badge, Collapse, Space, Table } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import CountryService from "../../services/country.service";

const { Panel } = Collapse;

const Countries = () => {
  const [countries, setCountries] = useState([]);
  const [countryActions, setCountryActions] = useState([]);
  const [stateActions, setStateActions] = useState([]);

  useEffect(() => {

    setCountryActions(AuthService.getActionByGroup("Country", "table"));
    setStateActions(AuthService.getActionByGroup("State", "table"));

    CountryService.getAllCountries().then(
      (response) => {
        console.log("Countrues ", response.data)
        setCountries(response.data);
      },
      (error) => {
        console.log("Error", error)
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);


  const columns = [
    { title: 'Nombre', dataIndex: 'name', key: 'name' },
    { title: 'Codigo', dataIndex: 'code', key: 'code' },
    {
      title: 'Estado', dataIndex: 'state', key: 'state',
      render: (e, record) => (
        <Badge
          text={record.active}
          count={
            record.active ? (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
              <ExclamationCircleOutlined style={{ color: 'red' }} />
            )
          } />)
    },
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <Space size="middle">
          {
            stateActions.map((action, index) => {
              return actionFactory.getAction(action, { id: record.id })
            })
          }
        </Space>
      ),
    }
  ]

  const genCountryActions = (country) => {
    return countryActions.map(action => {
      return actionFactory.getAction(action, { id: country.id })
    })
  }

  const drawAccordion = () => {
    return <Collapse accordion defaultActiveKey={[`accordion-0`]} >
      {
        countries.map((country, index) => {
          return (
            <Panel
              header={`${country.name}( ${country.code} )`}
              key={`accordion-${index}`}
              extra={genCountryActions(country)}>
              <ButtonActionRow group="State" />
              <Table
                columns={columns} dataSource={country.states}
              />
            </Panel>
          )
        })
      }
    </Collapse>

  }


  return (
    <>
      <ButtonActionRow group="Country" />
      {drawAccordion()}
    </>
  );
};

export default Countries;
