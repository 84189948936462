import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/fuelType`;

const getAll = () => {
  return axios.get(API_URL,{ headers: authHeader() });
};

const get = (fuelTypeId) => {
  return axios.get(`${API_URL}/${fuelTypeId}`, { headers: authHeader() });
};

const save = (fueltype) => {
  return axios.post(API_URL, fueltype, { headers: authHeader() });
};


const del = (fuelTypeId) => {
  return axios.delete(`${API_URL}/${fuelTypeId}`, { headers: authHeader() });
};

const update = (fuelTypeId, fuelType) => {
  return axios.put(`${API_URL}/${fuelTypeId}`, fuelType, { headers: authHeader() });
};


const FuelTypeService = {
  getAll,  
  save,
  update,
  delete: del,
  get
};

export default FuelTypeService;
