
import React from "react";
import MaintenancePlanInfo from "./MaintenanceInfo";
import { Col, Row } from "antd";
import UserInfo from "./UserInfo";
import VehicleInfo from "./VehicleInfo";

const Home = () => {
    
    return (
        <Row align="center" gutter={[8,16]}>
            <Col xs={24} xl={8}>
                <UserInfo />
            </Col>
            <Col  xs={24} xl={8}>
                <VehicleInfo />
            </Col>
            <Col xs={24} xl={8}>
                <MaintenancePlanInfo />
            </Col>
        </Row>
    );
}

export default Home;