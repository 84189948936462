import React, { useState, useEffect } from "react";

import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
import actionFactory from '../../common/ActionFactory'
import ButtonActionRow from "../../common/ButtonActionRow";
import { Badge, Space, Table } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined, FileDoneOutlined } from "@ant-design/icons";
import JourneyService from "../../services/journey.service";

const Journeys = () => {
  const [journeys, setContent] = useState([]);
  const [tableActions, setTableActions] = useState([]);

  useEffect(() => {

    setTableActions(AuthService.getActionByGroup("Journey", "table"));

    JourneyService.getAll().then(
      (response) => {
        console.log(response)
        setContent(response.data);
      },
      (error) => {
        console.log("Error", error)
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columns = [
    { title: 'Fecha', dataIndex: 'travelDate', key: 'travelDate' },
    { title: 'Hora', dataIndex: 'travelTime', key: 'travelTime' },
    { title: 'Origen', dataIndex: 'origin', key: 'origin' },
    { title: 'Destino', dataIndex: 'destination', key: 'destination' },
    { title: 'Vehiculo', dataIndex: 'code', key: 'vehicle' },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (e, record) => (
        <Badge
          text={record.status}
          size="medium"
          count={
            record.status==="done" ? (
              <FileDoneOutlined style={{ color: 'red' }} />
            ) : (
              <CheckCircleOutlined style={{ color: 'green' }} />
            )
          } />)
    },
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <Space size="middle">
          {
            tableActions.map((action, index) => {
              return actionFactory.getAction(action, { id: record.id })
            })
          }
        </Space>
      ),
    }
  ]

  const drawData = () => {
    if (!journeys || !Array.isArray(journeys) || journeys.length === 0) {
      <div>No content</div>
    } else {
      return journeys.map(journey=>{journey.key=journey.id;return journey;})
    }
  }

  return (
    <>
      <ButtonActionRow group="Journey" />

      <Table columns={columns} dataSource={drawData()} />
    </>
  );
};

export default Journeys;
