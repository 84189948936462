import { Routes, Route } from "react-router-dom";
import 'antd/dist/reset.css';
import './App.css';

import Login from "./components/Login";
import TrackerLayout from './components/layout/TrackerLayout';
import Profile from './components/profile/Profile';
import Users from './components/users/Users';
import AddUser from './components/users/AddUser';
import UpdateActions from './components/users/UpdateActions';
import ChangePassword from './components/users/ChangePassword';

import Vehicles from './components/vehicle/Vehicles';
import BusForm from "./components/vehicle/BusForm";
import DeleteVehicle from "./components/vehicle/DeleteVehicle";

import VehicleTypes from "./components/vehicle/type/types";
import VehicleTypeForm from "./components/vehicle/type/VehicleTypeForm";
// import Inventory from './components/inventory/Inventory';
import Maintenances from "./components/vehicle/maintenance/Maintenances";
import Operators from "./components/vehicle/operator/Operators";
import OperatorForm from "./components/vehicle/operator/OperatorForm";
import PlanServiceTemplateForm from "./components/vehicle/maintenance/PlanServiceTemplateForm";
import Countries from "./components/country/Countries";
import Destinations from "./components/journey/destination/Destinations";
import DestinationForm from "./components/journey/destination/DestinationForm";
import Journeys from "./components/journey/Journeys";
import JourneyForm from "./components/journey/JourneyForm";
import VehicleOperator from "./components/vehicle/VehicleOperators";
import JourneyOperator from "./components/journey/JourneyOperators";
import OilForm from "./components/vehicle/OilForm";
import Providers from "./components/provider/Providers";
import ProviderForm from "./components/provider/ProviderForm";
import FuelTypes from "./components/fuel/FuelTypes";
import FuelTypeCostForm from "./components/fuel/FuelTypeCostForm";
import Registry from "./components/Registry";
import VehicleMaintenance from "./components/vehicle/maintenance/VehicleMaintenance";
import PlanForm from "./components/vehicle/maintenance/PlanForm";
import Home from "./components/home/Home";
import Services from "./components/vehicle/maintenance/service/Services";
import ServiceForm from "./components/vehicle/maintenance/service/ServiceForm";

import VehicleTires from "./components/vehicle/VehicleTires";

import WorkOrders from "./components/vehicle/workorder/WorkOrders";
import Incidents from "./components/vehicle/incident/Incidents";
import IncidentForm from "./components/vehicle/incident/IncidentForm";
import WorkOrderForm from "./components/vehicle/workorder/WorkOrderForm";
import Stores from "./components/inventory/store/Stores";
import StoreForm from "./components/inventory/store/StoreForm";
import Products from "./components/inventory/product/Products";
import ProductForm from "./components/inventory/product/ProductForm";
import BuyForm from "./components/inventory/buy/BuyForm";
import Buys from "./components/inventory/buy/Buys";
import AssignTire from "./components/vehicle/tire/AssignTire";
import Tires from "./components/vehicle/tire/Tires";
import TireForm from "./components/vehicle/tire/TireForm";
import ChassisForm from "./components/vehicle/chassis/ChassisForm";



const App = () => {

  return (
    <Routes>
      <Route path='/' element={<TrackerLayout />}>
        <Route path="/" element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/profile' element={<Profile />} />
        <Route path="/register" element={<Registry />} />
        {/* Manejo de usuarios */}
        <Route path='/users' element={<Users />} />
        <Route path='/add-user' element={<AddUser />} />
        <Route path='/edit-user/:id' element={<AddUser />} />
        <Route path='/update-actions/:id' element={<UpdateActions />} />
        <Route path='/change-password/:id' element={<ChangePassword />} />

        <Route path='/routes' element={<Users />} />
        {/* Manejo de Vehiculos */}
        <Route path='/vehicles' element={<Vehicles />} />
        <Route path='/add-bus' element={<BusForm />} />
        <Route path='/edit-bus/:id' element={<BusForm />} />
        <Route path='/delete-bus/:id' element={<DeleteVehicle />} />
        <Route path='/vehicle-operators/:id' element={<VehicleOperator />} />
        <Route path='/add-oil/:id' element={<OilForm />} />
        <Route path='/manteinance/:id' element={<VehicleMaintenance />} />
        <Route path='/bus-tires/:id' element={<VehicleTires />} />
        <Route path='/vehicle/:id/chassis' element={<ChassisForm />} />

        <Route path='/vehicle-type' element={<VehicleTypes />} />
        <Route path='/add-vehicle-type' element={<VehicleTypeForm />} />
        <Route path='/edit-vehicle-type/:id' element={<VehicleTypeForm />} />
        <Route path='/delete-vehicle-type/:id' element={<Vehicles />} />

        <Route path='/operators' element={<Operators />} />
        <Route path='/add-operator' element={<OperatorForm />} />
        <Route path='/edit-operator/:id' element={<OperatorForm />} />

        <Route path='/destinations' element={<Destinations />} />
        <Route path='/add-destination' element={<DestinationForm />} />
        <Route path='/edit-destination/:id' element={<DestinationForm />} />
        <Route path='/delete-destination/:id' element={<DestinationForm />} />

        <Route path='/journeys' element={<Journeys />} />
        <Route path='/add-journey' element={<JourneyForm />} />
        <Route path='/edit-journey/:id' element={<JourneyForm />} />
        <Route path='/journey-operators/:id' element={<JourneyOperator />} />

        {/* Manejo de Mantenimientos */}
        <Route path='/plans' element={<Maintenances />} />
        <Route path='/plan-details/:id' element={<PlanServiceTemplateForm />} />
        <Route path='/add-plan' element={<PlanForm />} />
        <Route path='/edit-plan/:id' element={<PlanForm />} />
        <Route path='/delete-maintenance-plan/:id' element={<PlanForm />} />

        <Route path='/services' element={<Services />} />
        <Route path='/add-service' element={<ServiceForm />} />
        <Route path='/edit-service/:id' element={<ServiceForm />} />
        
        <Route path="/work-order" element={<WorkOrders/>}/>

        <Route path="/incidents" element={<Incidents/>}/>
        <Route path="/add-incident" element={<IncidentForm/>}/>
        <Route path="/edit-incident/:id" element={<IncidentForm/>}/>
        <Route path="/add-incident-work/:id" element={<WorkOrderForm/>}/>

        {/* Manejo de Inventarios */}
        <Route path="/stores" element={<Stores/>}/>
        <Route path="/add-store" element={<StoreForm/>}/>
        <Route path="/edit-store/:id" element={<StoreForm/>}/>

        <Route path="/products" element={<Products/>}/>
        <Route path="/add-product" element={<ProductForm/>}/>
        <Route path="/edit-product/:id" element={<ProductForm/>}/>

        <Route path="/buys" element={<Buys/>}/>
        <Route path="/add-buy" element={<BuyForm/>}/>
        {/* Configuracion */}

        <Route path='/countries' element={<Countries />} />

        <Route path='/providers' element={<Providers />} />
        <Route path='/add-proveedor' element={<ProviderForm />} />
        <Route path='/edit-proveedor/:id' element={<ProviderForm />} />

        <Route path='/fuel-costs' element={<FuelTypes />} />
        <Route path='/add-fuel-cost' element={<FuelTypeCostForm />} />
        <Route path='/edit-fuel-cost/:id' element={<FuelTypeCostForm />} />

        {/* Manejo de llantas */}
        <Route path='/tires' element={<Tires />} />
        <Route path='/add-tire' element={<TireForm />} />
        <Route path='/edit-tire/:id' element={<TireForm />} />
        <Route path='/assign-tire' element={<AssignTire />} />

      </Route>

    </Routes>
  );
}

export default App;
