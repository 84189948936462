import axios from "axios";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/auth/signup`;

const registry = (registry) => {
  return axios.post(API_URL, registry);
};


const RegistryService = {
  registry
  
};

export default RegistryService;
