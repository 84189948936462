import React, { useState, useEffect } from "react";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Col, Modal, Row, Select, Space, message as popMessage } from "antd";

import VehicleService from "../../../services/vehicle.service";
import errorService from "../../../services/error.service";
import ChassisModelService from "../../../services/chassisModel.service";
import FormItem from "antd/es/form/FormItem";
import ModelDrawer from "../tire/ModelDrawer";
import { DndProvider } from "react-dnd";



const ChassisForm = () => {
    const [messageApi, contextHolder] = popMessage.useMessage();

    let navigate = useNavigate();
    const location = useLocation();
    const routeTo = location.state?.from?.pathname || "/vehicles";

    const params = useParams();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [model, setModel] = useState(null)
    const [hasModel, setHasModel] = useState(false)
    const [vehicle, setVehicle] = useState(null)
    const [models, setModels] = useState([])
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedModelId, setSelectedModelId] = useState(-1);


    useEffect(() => {

        if (params.id) {
            VehicleService.getBus(params.id).then(
                (responseVe) => {
                    const vehicleR = responseVe.data;
                    setVehicle(vehicleR)
                    console.log("selected vehicle : ", vehicleR, "hasModel", hasModel)
                    if (vehicleR?.chassisModel != null) {
                        setHasModel(true);
                        setModel(vehicleR.chassisModel);
                        getModelItems(vehicleR.chassisModel.id)
                    } else {
                        ChassisModelService.getAll().then(
                            (responseModel) => {
                                console.log("models :", responseModel.data)
                                setModels(responseModel.data.map(mod => {
                                    mod.label = mod.name;
                                    mod.value = mod.id;
                                    return mod;
                                }));
                            },
                            (error) => {
                                setMessage(errorService.checkRequest(error));
                            }
                        );
                    }
                },
                (error) => {
                    setMessage(error)
                })
        }
    }, [])

    const closeModal = () => {
        navigate(routeTo, { replace: true });
    }

    const handleSubmit = (oilData) => {

        setMessage("");
        setLoading(true);

        VehicleService.addOil(params.id, oilData).then(
            (response) => {
                navigate(routeTo, { replace: true });
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
                setLoading(false);
            }
        )
    }

    const handleOk = () => {
        setLoading(true);
        if (selectedModelId !== -1) {
            VehicleService.assignChassisModel(vehicle.id, selectedModelId).then(
                (response) => {
                    if (response.data) {
                        navigate(routeTo, { replace: true });
                    }
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            );
        }

    }

    const onSelectModel = (modelId) => {
        if (modelId === -1) {
            messageApi.info('Seleccione un modelo valido para ver los items');
            setSelectedModel(null);
        } else {
            setSelectedModelId(modelId);
            getModelItems(modelId);
        }
    }

    const getModelItems = (modelId) => {
        ChassisModelService.getItems(modelId).then(
            (response) => {
                const model = response.data;
                setSelectedModel(model);
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        )
    }

    return (
        <>
            <Modal
                open={true}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                title={`Modelo de chassis para vehiculo`}
                height={'80%'}
            >
                {contextHolder}
                <Row>
                    {hasModel && <Col>
                        <Space direction="vertical">

                            <div>{`Code: ${vehicle.code}`}</div>
                            <div>{`Plate: ${vehicle.plate}`}</div>
                            <div>{`Asigned Model: ${model.name}`}</div>
                            <div>
                                <DndProvider backend={HTML5Backend}>
                                    <ModelDrawer view="horizontal" model={selectedModel} />
                                </DndProvider>
                            </div>
                        </Space>
                    </Col>
                    }
                    {!hasModel && <Col>
                        <Space direction="vertical">
                            <Alert
                                message={`Model not Asigned`}
                                description="below you have al the available chassis models, please select one to draw it and select one"
                                type="warning"
                                closable />
                            <FormItem label="Models" style={{ width: "100%" }}>
                                <Select options={models} placeholder="Select a model" onSelect={onSelectModel} />
                            </FormItem>
                            <DndProvider backend={HTML5Backend}>
                                <ModelDrawer view="horizontal" model={selectedModel} />
                            </DndProvider>
                        </Space>
                    </Col>
                    }
                </Row>
                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}

            </Modal>
        </>
    )
}

export default ChassisForm;