import { useEffect, useState } from 'react';

import { Menu } from 'antd'
import AuthService from "../../services/auth.service";
import defaultMenu from "../home/defaultMenu";

function SideBar(props) {
    
    const [menus, setMenus] = useState([]);
    const [openKeys, setOpenKeys] = useState(['sider-1']);

    const changeSelectedkey = (event) => {
        const key = event.key;
        console.log("on selecte menu ", event.keyPath[1])
    }

    useEffect(() => {

        const user = AuthService.getCurrentUser();
        if (user) {
            let userMenu = AuthService.getActionByGroup('Menu', 'List');
            let assignedMenu = [];
            userMenu.forEach(rol => {
                defaultMenu().forEach(menu => {
                    let assigned = menu.children.find(sbm => sbm.key === rol.name);
                    let currentIndex = assignedMenu.findIndex(m => m.key === menu.key);
                    if (assigned) {
                        assigned.onClick=changeSelectedkey;
                        if (currentIndex !== -1) {
                            assignedMenu[currentIndex].children.push(assigned);
                        } else {
                            let newMenu = menu;
                            newMenu.children = [assigned];                            
                            assignedMenu.push(newMenu);
                        }
                    }
                })

            });
            setMenus(assignedMenu);            
        }
    }, []);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        latestOpenKey ? setOpenKeys([latestOpenKey]) : setOpenKeys(['sider-1']);
    };

    return (
        <>
            <Menu
                mode="inline"
                theme='dark'
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                items={menus}
            />
        </>
    );
}

export default SideBar;