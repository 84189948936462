import React, { useState, useEffect } from "react";
import { Space, Table } from 'antd';

import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
import actionFactory from '../../common/ActionFactory'
import ButtonActionRow from "../../common/ButtonActionRow";

const Users = () => {
  const [content, setContent] = useState([]);
  const [tableActions, setTableActions] = useState([]);
  

  useEffect(() => {
    setTableActions(AuthService.getActionByGroup("user", "table"));
    

    UserService.getAllUsers().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columns = [
    { title: 'Nombre', dataIndex: 'name', key: 'name'},
    { title: 'Apellidos', dataIndex: 'lastname', key: 'lastname' },
    { title: 'Usuario', dataIndex: 'username', key: 'username' },
    { title: 'email', dataIndex: 'email', key: 'email'},
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <Space size="middle">
          {
          tableActions.map((action, index) => {
            return actionFactory.getAction(action, { id: record.userId })
          })
          }
        </Space>
      )
    }
  ]
  const data = () => {
    if (!content|| !Array.isArray(content) || content.length === 0) {
      <div>No content</div>
    } else {
      return content.map((user, i) => {
        return {
          key: i + 1,
          userId: user.userId,
          name: user.name,
          lastname: user.lastname,
          username: user.username,
          email: user.email
        }
      })
    }
  }

  return (
    <>
      <ButtonActionRow group="user"/>
      <Table columns={columns} dataSource={data()} />

    </>
  );
};

export default Users;
