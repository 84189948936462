import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/buy`;

const update = (buyId, buyData) => {
  return axios.put(`${API_URL}/${buyId}`, buyData, { headers: authHeader() });
};

const findById = (buyId) => {
  return axios.get(`${API_URL}/${buyId}`, { headers: authHeader() });
};

const save = (buyData) => {
  return axios.post(API_URL, buyData, { headers: authHeader() });
};

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const drop = (buyId) => {
  return axios.delete(`${API_URL}/${buyId}`, { headers: authHeader() });
};

const BuyService = {
  save,
  update,
  getAll,
  findById,
  delete:drop
};

export default BuyService;
