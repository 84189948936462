import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import errorService from "../../../services/error.service";
import { Alert, Form, Input, Modal } from "antd";
import Messages from "../../../common/messages";
import IncidentService from "../../../services/incident.service";
import eventBus from "../../../common/EventBus";
import Tracker from "../../../common/Constants";
import StoreService from "../../../services/store.service";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 15 } };

const StoreForm = () => {


    let navigate = useNavigate();
    const location = useLocation();
    const toIncidentsLocation = location.state?.from?.pathname || "/stores";

    const params = useParams();
    const [form] = Form.useForm();


    const [isOpen, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    
    useEffect(() => {
        if (params.id) {
            setTitle('Edicion');
            setEditMode(true);
            IncidentService.getById(params.id).then(
                (response) => {
                    const stores = response.data;
                    form.setFieldsValue(stores);
                }, (error) => {
                    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                        eventBus.dispatch("logout");
                    }
                    setMessage(errorService.checkRequest(error));
                }
            );
        } else {

        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toIncidentsLocation, { replace: true });
    }

    const handleSubmit = (incidentData) => {

        setMessage("");
        setLoading(true);

        if (editMode) {
            StoreService.update(params.id, incidentData).then(
                (response) => {
                    setOpen(false);
                    navigate(toIncidentsLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {

            StoreService.save(incidentData).then(
                (response) => {
                    setOpen(false);
                    navigate(toIncidentsLocation, { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
            >
            </div>

            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                centered
                title={`${title} de Almacenes`}
            >
                
                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    labelAlign='left'
                    validateMessages={Messages.common}
                >
                    <Form.Item label='Nombre' name='name' rules={Tracker.Validation.requiredOnly}>
                        <Input placeholder="Tumusla"/> 
                    </Form.Item>
                    <Form.Item label='Direccion' name='address' rules={Tracker.Validation.requiredOnly}>
                        <Input placeholder="Av calancha nro 100" />
                    </Form.Item>
                    <Form.Item label='telefono' name={'telephone'} rules={Tracker.Validation.requiredOnly}>
                        <Input type="telephone" />
                    </Form.Item>
                    
                </Form>
                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Modal>
        </>
    )
}
export default StoreForm;