import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import { Alert, Form, Input, Modal, Select, Switch } from "antd";
import errorService from "../../services/error.service";
import Messages from "../../common/messages";
import ProviderService from "../../services/provider.service";
import Tracker from "../../common/Constants";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const ProviderForm = () => {


    let navigate = useNavigate();
    const location = useLocation();
    const toProvidersLocation = location.state?.from?.pathname || "/providers";

    const params = useParams();
    const [form] = Form.useForm();


    const [isOpen, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {

        if (params.id) {
            setTitle('Edicion');
            setEditMode(true);
            ProviderService.findById(params.id).then(
                (response) => {
                    const operator = response.data;
                    form.setFieldsValue(operator);
                }, (error) => {
                    setMessage(errorService.checkRequest(error));
                }
            );
        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toProvidersLocation, { replace: true });
    }

    const handleSubmit = (providerData) => {

        setMessage("");
        setLoading(true);


        if (editMode) {

            ProviderService.update(params.id, providerData).then(
                (response) => {
                    setOpen(false);
                    navigate(toProvidersLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {
            ProviderService.create(providerData).then(
                (response) => {
                    setOpen(false);
                    navigate(toProvidersLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
            >
            </div>

            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                centered
                title={`${title} de Proveedores`}
            >
                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    labelAlign='left'
                    validateMessages={Messages.common}
                >
                    <Form.Item label='Nombre' name='name' required rules={Tracker.Validation.requiredOnly}>
                        <Input placeholder="San Jorge" />
                    </Form.Item>

                    <Form.Item label='NIT' name='nit' >
                        <Input placeholder="123456789" />
                    </Form.Item>

                    <Form.Item label='Telefono' name='phone' >
                        <Input placeholder="764-35897" />
                    </Form.Item>

                    <Form.Item label='Contacto' name='contact' required rules={Tracker.Validation.requiredOnly}>
                        <Input placeholder="Juan Ramirez" />
                    </Form.Item>

                    <Form.Item label='Direccion' name='address' required rules={Tracker.Validation.requiredOnly}>
                        <Input placeholder="Av chiquicollo" />
                    </Form.Item>

                    <Form.Item label='Tipo' name='type' required rules={Tracker.Validation.requiredOnly}>
                        <Select>
                            <Select.Option value="1">Surtidor</Select.Option>
                            <Select.Option value="2">Repuestos</Select.Option>
                            <Select.Option value="3">Insumos</Select.Option>
                            <Select.Option value="4">Otros</Select.Option>
                        </Select>

                    </Form.Item>

                    <Form.Item label='Estado' name='active' valuePropName="checked" rules={Tracker.Validation.requiredOnly}>
                        <Switch />
                    </Form.Item>

                    {message && (
                        <Alert
                            message="Request error"
                            description={message}
                            type="error"
                            closable
                        />
                    )}
                </Form>
            </Modal>
        </>
    )
}
export default ProviderForm;