import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Alert, DatePicker, Divider, Form, InputNumber, Modal, Space, Switch } from "antd";
import dayjs from "dayjs";

import errorService from "../../services/error.service";
import Messages from "../../common/messages";
import FuelTypeCostService from "../../services/fuelTypeCost.service";
import FuelTypeService from "../../services/fuelType.service";
import { useRef } from "react";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const FuelTypeCostForm = () => {


    let navigate = useNavigate();
    const location = useLocation();
    const toLocation = location.state?.from?.pathname || "/fuel-costs";

    const params = useParams();
    const [searchParams] = useSearchParams();
    let fuelTypeId = useRef(searchParams.get('parentId'))
    let fuelTypeName = useRef(searchParams.get('parentName'))
    const [form] = Form.useForm();


    const [isOpen, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        console.log("parentId", fuelTypeName)
        if (params.id) {
            setTitle('Edicion');
            setEditMode(true);
            FuelTypeCostService.get(params.id).then(
                (response) => {
                    const fuelTypeCost = response.data;
                    fuelTypeCost.from = dayjs(fuelTypeCost.from);
                    form.setFieldsValue(fuelTypeCost);
                    FuelTypeService.get(fuelTypeCost.fuelType.id).then(
                        (res) => {
                            console.log(res)
                            fuelTypeId.current = res.data.id;
                            fuelTypeName.current = res.data.name
                            console.log("parentId", fuelTypeName)
                        },
                        (error) => { setMessage(errorService.checkRequest(error)); }
                    )
                }, (error) => {
                    setMessage(errorService.checkRequest(error));
                }
            );
        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toLocation, { replace: true });
    }

    const handleSubmit = (costData) => {

        setMessage("");
        setLoading(true);


        if (editMode) {

            FuelTypeCostService.update(params.id, costData).then(
                (response) => {
                    setOpen(false);
                    navigate(toLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {
            FuelTypeCostService.save(costData).then(
                (response) => {
                    setOpen(false);
                    navigate(toLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                values.fuelType = { id: fuelTypeId.current }

                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <>
            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                centered
                title={`${title} de Precios de combustible`}
            >

                <Space direction="vertical" size="middle">
                    <Alert type="warning" message="Adding a new price will deactivate all the other prices for this  fuel type" />
                    <Divider orientation="left">{fuelTypeName.current}</Divider>
                </Space>
                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    labelAlign='left'
                    validateMessages={Messages.common}
                >
                    <Form.Item label='Precio' name='cost' required rules={[
                        {
                            required: true
                        }
                    ]}>
                        <InputNumber precision={2} placeholder="3.16" />
                    </Form.Item>
                    <Form.Item label='Inicio' name='from' required rules={[
                        {
                            required: true
                        }
                    ]}>
                        <DatePicker />

                    </Form.Item>
                    <Form.Item label='Hasta' name='to' >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item label='Estado' name='active' valuePropName="checked" rules={[
                        {
                            required: true
                        }
                    ]}>
                        <Switch />
                    </Form.Item>

                    {message && (
                        <Alert
                            message="Request error"
                            description={message}
                            type="error"
                            closable
                        />
                    )}
                </Form>
            </Modal>
        </>
    )
}
export default FuelTypeCostForm;