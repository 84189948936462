import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/journey`;

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getById = (journeyId) => {
  return axios.get(`${API_URL}/${journeyId}`, { headers: authHeader() });
};

const save = (journey) => {
  return axios.post(API_URL, journey, { headers: authHeader() });
};


const deleteJourney = (journeyId) => {
  return axios.delete(`${API_URL}/${journeyId}`, { headers: authHeader() });
};

const update = (journeyId, journey) => {
  return axios.put(`${API_URL}/${journeyId}`, journey, { headers: authHeader() });
};

const getAssigned = (journeyId) => {
  return axios.get(`${API_URL}/${journeyId}/operators`, { headers: authHeader() });
};

const updateAssignedOperators = (journeyId, operators) => {
  return axios.put(`${API_URL}/${journeyId}/operators`, operators, { headers: authHeader() });
};

const getJourneyByBusIdAndDestinationId = (vehicleId, destinationId) => {
  return axios.get(`${API_URL}?vehicle=${vehicleId}&destination=${destinationId}`, { headers: authHeader() });
};

const completeJourneys = (JourneysStatus) => {
  return axios.put(`${API_URL}/bulk/status`, JourneysStatus, { headers: authHeader() });
};
const JourneyService = {
  getAll,
  save,
  update,
  deleteJourney,
  getById,
  getAssigned,
  updateAssignedOperators,
  getJourneyByBusIdAndDestinationId,
  completeJourneys
};

export default JourneyService;
