import React, { useState } from 'react';
import { Button, Dropdown, Popconfirm, Space, Table, Tooltip } from 'antd';
import useActionMenu from './useActionMenu';
import { DeleteOutlined, DownOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;

function useDataTable({ columns, dataSource, actions, handleSingleDelete }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const hasSelected = selectedRowKeys.length > 0;
  const defaultDeleteHandler = () => {
    console.log("delete handler is not implemented, default is only loging info")
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: selected => {
      setSelectedRowKeys(selected);
    },
  };

  const editLabel = (action, row) => {
    return (
      <Tooltip key={`editMenu-${action.id}`} title={action.displayName}>
        <Link to={`${action.function}/${row?.id}`} target="_self" rel="noreferrer">
          <EditOutlined style={{ width: '25px', color: 'orange' }} />{action.displayName}
        </Link>
      </Tooltip>)
  }
  const deleteLabel = (action, row) => {
    return (
      <Popconfirm
        title="Sure to delete?"
        placement="left"
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => { handleSingleDelete?handleSingleDelete(row):defaultDeleteHandler() }}
      >
        <Tooltip key={`deleteMenu-${action.id}`} title={action.displayName}>
          <Button type='link' onClick={(event) => { event.stopPropagation() }}>
            <DeleteOutlined type='delete' style={{ width: '25px', color: 'red' }} />{action.displayName}
          </Button>
        </Tooltip>
      </Popconfirm>
    )
  }

  const otherLabel = (action, row) => {
    return (
      <Tooltip key={`otherMenu-${action.id}`} title={action.displayName}>
        <Link to={`${action.function}/${row?.id}`} target="_self" rel="noreferrer">
          <EditOutlined style={{ width: '25px', color: 'orange' }} />{action.displayName}
        </Link>
      </Tooltip>
    )
  }

  const updatedColumns = [
    ...columns,
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {

        const items = actions.map(action => {
          if (action.name.indexOf("EDIT") !== -1) {
            return {
              key: 'edit',
              label: editLabel(action, record)
            }
          }

          if (action.name.indexOf("DELETE") !== -1) {
            return {
              key: 'delete',
              label: deleteLabel(action, record)
            }
          }
          return {
            key: `otherMenu-${action.id}`,
            label: otherLabel(action, record)
          }
        });
        return (
          <span>
            <Dropdown menu={{ items }} >
              <Space>
                <Button type='link'>
                  acciones
                  <DownOutlined />
                </Button>
              </Space>
            </Dropdown>
          </span>
        );
      },
    },
  ];

  const resetPagination = () => {
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const handleTableChange = pagination => {
    console.log('pagination:', pagination);
    setCurrentPage(pagination.current - 1);
  };

  const DataTable = () => (
    <Table
      rowKey={record => record.id}
      rowSelection={rowSelection}
      columns={updatedColumns}
      dataSource={dataSource.content}
      onRow={record => {
        return {
          onClick: () => {
            setSelectedRow(record);
          },
        };
      }}
      onChange={handleTableChange}
      pagination={{
        pageSize: DEFAULT_PAGE_SIZE,
        current: currentPage + 1,
        total: dataSource.totalElements,
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} of ${total} items`;
        },
      }}
    />
  );

  return {
    DataTable,
    hasSelected,
    selectedRow,
    selectedRowKeys,
    currentPage,
    pageSize,
    resetPagination,
  };
}

export default useDataTable;