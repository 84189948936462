export var OverlayType;

export const Overlay = () => {

  return (
    <div
      className="overlay"
      key={Math.random()*10000}
      role={"Legal"}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1,
        opacity: 0.5,
        backgroundColor: "green",
      }}
    />
  )
}

