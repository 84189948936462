import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/incident`;

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getById = (incidentId) => {
  return axios.get(`${API_URL}/${incidentId}`, { headers: authHeader() });
};

const save = (incident, files) => {
  let data = new FormData();
  incident.imagePath = files[0].name;
  data.append("model", JSON.stringify(incident));
  data.append("file", files[0].originFileObj, files[0].name);
  let headers = authHeader();  
  headers["Content-Type"]='multipart/form-data'
  return axios.post(API_URL, data, {headers});
};


const deleteIncident = (incidentId) => {
  return axios.delete(`${API_URL}/${incidentId}`, { headers: authHeader() });
};

const update = (incidentId, incident, files) => {
  let data = new FormData();
  incident.imagePath = files[0].name;
  data.append("model", JSON.stringify(incident));
  data.append("file", files[0]?.originFileObj, files[0].name);
  let headers = authHeader();
  
  headers["Content-Type"]='multipart/form-data'
  
  return axios.put(`${API_URL}/${incidentId}`, data, { headers });
};


const IncidentService = {
  getAll,
  save,
  update,
  deleteIncident,
  getById

};

export default IncidentService;
