import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import RoleService from "../../services/role.service";
import UserService from "../../services/user.service";
import { useEffect } from "react";
import { Alert, DatePicker, Form, Input, Modal, Select } from "antd";
import errorService from "../../services/error.service";

const AddUser = () => {

    const [form] = Form.useForm();
    const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 12 } };

    let navigate = useNavigate();
    const location = useLocation();
    const toLocation = location.state?.from?.pathname || "/users";

    const params = useParams();

    const [role, setRole] = useState('');

    const [isOpen, setOpen] = useState(true);
    //const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [roles, setRoles] = useState([]);

    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (params.id) {
            setTitle('Edicion');
            setEditMode(true);
            UserService.getUser(params.id).then(
                (response) => {
                    const user = response.data;
                    form.setFieldsValue(user)
                }, (error) => {
                    console.log("error edit mode", error);
                    setMessage(errorService.checkRequest(error));
                }
            );
        }
        RoleService.getAll().then(
            (response) => {
                setRoles(response.data);
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );
    }, []);


    let isValid = true;
    const closeModal = () => {
        setOpen(false);
        navigate("/users", { replace: true });
    }

    const handleChange = () => { }

    const handleSave = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const handleSubmit = (userData) => {
        setMessage("");
        setLoading(true);


        console.log('The user data', userData)

        if (editMode) {
            console.log('edit mode')
            UserService.updateUser(params.id, userData).then(
                (response) => {
                    setOpen(false);
                    navigate(toLocation, { replace: true });
                    isValid = true;
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                    isValid = true;
                }
            )
        } else {
            UserService.saveUser(userData).then(
                (response) => {
                    setOpen(false);
                    navigate(toLocation, { replace: true });
                },
                (error) => {
                    console.log(error)
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                    isValid = true;
                }
            )
        };
    }

    return (
        <>
            <Modal
                open={isOpen}
                title={`${title} de Usuario`}
                onOk={handleSave}
                okText='Save'
                onCancel={closeModal}
                okButtonProps={{ disabled: !isValid }}
            >
                <Form
                    {...formItemLayout}
                    onFinish={handleSubmit}
                    layout='horizontal'
                    form={form}>

                    <Form.Item label='Nombre:' name='name'>
                        <Input placeholder="Juan" />
                    </Form.Item>

                    <Form.Item label='Apellido Paterno:' name='lastname'>
                        <Input placeholder="Perez" />
                    </Form.Item>

                    <Form.Item label='Apellido Materno:' name='motherLastname'>
                        <Input placeholder="Paria" />
                    </Form.Item>

                    <Form.Item label='Email:' name='email'>
                        <Input type="email" placeholder="Email" />

                    </Form.Item>

                    <Form.Item label='F. Nacimiento:' name='birthDate'>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item label='Cedula:' name='document'>
                        <Input placeholder="1234567" />
                    </Form.Item>

                    <Form.Item label='username:' name='username'>
                        <Input placeholder="something" />
                    </Form.Item>
                    {editMode === false && (
                        <>
                            <Form.Item label='Password:' name='password'>
                                <Input.Password placeholder="secreto" />
                            </Form.Item>
                            <Form.Item label='Role:' name='roleId'>
                                <Select as="select" name="Role" onChange={handleChange} value={role} isInvalid={!!errors.role}>
                                    <option value="-1">Select Role</option>
                                    {roles.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </>
                    )}

                    {message && (
                        <Alert
                            message="Request error"
                            description={message}
                            type="error"
                            closable
                        />
                    )}
                </Form>
            </Modal >
        </>
    )
}
export default AddUser;