import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/organization`;

const get = (orgid) => {
  return axios.get(`${API_URL}/${orgid}`, { headers: authHeader() });
};


const OrganizationService = {
  get
  
};

export default OrganizationService;
