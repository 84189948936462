import React, { useEffect, useState } from "react";

import StoreService from "../../../services/store.service";
import useDataTable from "../../../hooks/useDataTable";
import eventBus from "../../../common/EventBus";
import ButtonActionRow from "../../../common/ButtonActionRow";
import errorService from "../../../services/error.service";
import AuthService from "../../../services/auth.service";


const Stores = () => {
  const [message, setMessage] = useState("")
  const handleDelete= (row)=>{
    StoreService.deleteIncident(row.id).then(
      (response) =>{

      }
    ).catch((error)=>{})
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Direccion',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Telefono',
      dataIndex: 'telephone',
      key: 'phone'      
    }    
  ];
  const [data, setData] = useState([]);
  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    resetPagination,
  } = useDataTable({
    columns,
    dataSource: data,
    actions: AuthService.getActionByGroup("Store", "table"),
    handleSingleDelete:handleDelete
  });
  useEffect(() => {
    StoreService.getAll()
      .then((response) => {
        console.log("the data", response)
        setData({ content: response.data, totalElements: response.data.lenght });

      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          eventBus.dispatch("logout");
        }
        setMessage(errorService.checkRequest(error));
      })
  }, []);
  return (
    <>
      <ButtonActionRow group="Store" />
      <DataTable />
    </>
  );
};

export default Stores;
