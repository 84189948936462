import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/vehicleType`;

const getAll = () => {
  return axios.get(API_URL,{ headers: authHeader() });
};

const getType = (typeId) => {
  return axios.get(`${API_URL}/${typeId}`, { headers: authHeader() });
};

const save = (type) => {
  return axios.post(API_URL, type, { headers: authHeader() });
};


const deleteType = (typeId) => {
  return axios.delete(`${API_URL}/${typeId}`, { headers: authHeader() });
};

const update = (typeId, type) => {
  return axios.put(`${API_URL}/${typeId}`, type, { headers: authHeader() });
};

const VehicleTypeService = {
  getAll,  
  save,
  update,
  deleteType,
  getType
};

export default VehicleTypeService;
