import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { ModelItem } from "./ModelItem";


const ModelDrawer = ({ view = "horizontal", model = null, assignedTires = [] }) => {


    useEffect(() => {
        console.log("Reloading Model drawer with assigend", assignedTires)
    }, [assignedTires])

    const renderColumns = (row, cols, rowItems) => {
        let columns = [];
        for (let j = 0; j <= cols; j++) {
            const selectedItem = rowItems.find(item => { return item.positionY === j && item.positionX === row })
            columns.push(<ModelItem key={`d-c-i${row}-${j}`} col={j} row={row} item={selectedItem} assignedTires={assignedTires} />);
        }
        return columns
    }

    const drawHorizontal = () => {
        if (model === null) {
            return drawEmpty()
        }

        let rows = [];
        for (let i = 0; i <= model.maxRows; i++) {
            const rowItems = model.items.filter(item => { return item.positionX === i })
            rows.push(<Row key={`d-r${i}`}>{renderColumns(i, model.maxCols, rowItems)}</Row>);
        }
        return rows;
    }

    const drawVertical = () => {
        console.log("drawing Vertically")
    }

    const drawEmpty = () => {
        return <Row><Col span={20}>No valid model selected</Col></Row>
    }

    return (
        <>{
            view === "horizontal" ? drawHorizontal() : drawVertical()
        }
        </>
    )
}

export default ModelDrawer;
