import React, { useState, useEffect } from "react";

import AuthService from "../services/auth.service";
import actionFactory from './ActionFactory'


const headerStyle = {
    textAlign: 'right',
    height: 50,
    paddingInline: 50,
    lineHeight: '64px',

};

const ButtonActionRow = (props) => {
    const [buttonActions, setButtonActions] = useState([]);

    useEffect(() => {
        setButtonActions(AuthService.getActionByGroup(props.group, "button"));
    }, []);

    return (
        <div style={headerStyle}>
            {
                buttonActions.map(action => {
                    return (
                        actionFactory.getAction(action, props.extraParams)
                    )
                }
                )
            }
        </div>
    );
}

export default ButtonActionRow;