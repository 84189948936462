import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import VehicleService from "../../services/vehicle.service";
import EventBus from "../../common/EventBus";

const DeleteVehicle = () => {
    const params = useParams();
    let navigate = useNavigate();
    useEffect(() => {

        VehicleService.deleteBus(params.id).then(
            (response) => {
                navigate("/vehicles", { replace: true });    
            },
            (error) => {
                console.log("Error", error)
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    });



    return (
        <div>Empty</div>
    );
};

export default DeleteVehicle;
