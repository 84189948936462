import { Card, Carousel, Image } from "antd";
import React from "react";
import cardInfo from "../../img/car-parts-1200px-by-628px.png"
import serviceInfo from "../../img/maintenance-plan-services.png"
import namePlan from "../../img/name-maintenance-plan-services.png"
import applyPlan from "../../img/apply-maintenance-plan-template.png"
const MaintenancePlanInfo = () => {

    const contentStyle = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    }

    return (
        <Card
            title="Plan de mantenimientos"
            cover={
                <Carousel autoplay>
                    <div>
                        <h3 style={contentStyle}>
                            <Image src={cardInfo}></Image>
                        </h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>
                            <Image src={serviceInfo}></Image>
                        </h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>
                            <Image src={namePlan}></Image>
                        </h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>
                            <Image src={applyPlan}></Image>
                        </h3>
                    </div>
                </Carousel>}            
            style={{
                width: 300,
            }}>
            <p>Un plan de mantenimientos es la coleccion de servicios applicables a un vehiculo,
                con este plan de mantenimientos nuestro sistema puede generar los recordatorios para los servicios</p>
            <p>Per con un Template de plan de mantenimientos, tu puedes manejar todos los vehiculos de las mismas caracteristicas con un mismo plan.</p>
        </Card>
    );
}

export default MaintenancePlanInfo;