import eventBus from "../common/EventBus";


const checkRequest = (error) => {
    if (error) {

        if (error.response && error.response.status === 401) {
            eventBus.dispatch("logout");
        }
        
        return (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
    }

    return "No error provided";
}

const errorService = {
    checkRequest
}

export default errorService;