import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import errorService from "../../../services/error.service";
import { Alert, Collapse, Descriptions, Form, Modal, Switch } from "antd";

import Messages from "../../../common/messages";
import OperatorService from "../../../services/operator.service";
import moment from "moment/moment";
import MaintenancePlanService from "../../../services/planTemplate.service";

const { Panel } = Collapse;
const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const PlanServiceTemplateForm = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const toPlansLocation = location.state?.from?.pathname || "/plans";

    const params = useParams();
    const [form] = Form.useForm();


    const [isOpen, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    const [services, setServices] = useState([]);

    useEffect(() => {

        if (params.id) {
            setTitle('Edicion');
            setEditMode(true);
            MaintenancePlanService.getServicesByTemplatePlan(params.id).then(
                (response) => {
                    const services = response.data;
                    setServices(services);
                }, (error) => {
                    console.log(error)
                    setMessage(errorService.checkRequest(error));
                }
            );
        } else {

        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toPlansLocation, { replace: true });
    }

    const handleSubmit = (operatorData) => {

        setMessage("");
        setLoading(true);


        if (editMode) {
            operatorData.expirationDate = moment(operatorData.expirationDate, 'YYYY-MM-DD');
            OperatorService.update(params.id, operatorData).then(
                (response) => {
                    setOpen(false);
                    navigate(toPlansLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {
            OperatorService.save(operatorData).then(
                (response) => {
                    setOpen(false);
                    navigate(toPlansLocation, { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const drawServices = () => {
        console.log(services)       

        return (
            <Collapse accordion defaultActiveKey={[`accordion-0`]} >
                {
                    services.map((service,index) => {
                        return (
                            <Panel header={service.name} key={`accordion-${index}`}>
                                <Descriptions column={1}> 
                                    <Descriptions.Item label='Tipo de Recurrencia'>{service.type}</Descriptions.Item>
                                    <Descriptions.Item label='Por KM/M'>{service.mileage}</Descriptions.Item>
                                    <Descriptions.Item label='Por Tiempo'>{service.timer}</Descriptions.Item>
                                </Descriptions>
                            </Panel>
                        )
                    })
                }
            </Collapse>
        )
    }

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
            >
            </div>

            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                centered
                title={`${title} de Operadores`}
            >
                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    labelAlign='left'
                    validateMessages={Messages.common}
                >
                    {
                        drawServices()
                    }

                    {message && (
                        <Alert
                            message="Request error"
                            description={message}
                            type="error"
                            closable
                        />
                    )}
                </Form>
            </Modal>
        </>
    )
}
export default PlanServiceTemplateForm;