import React from 'react';
import { Button, Dropdown, Popconfirm, Space, Tooltip } from 'antd';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

function useActionMenu({ selectedRow, actions, handleSingleDelete }) {
  
  const items = actions.map(action => {

    if (action.name.indexOf("EDIT") !== -1) {
      return {
        key: 'edit',
        label: <Tooltip key={action.id} title={action.displayName}>
          <Link to={`${action.function}/${selectedRow?.id}`} target="_self" rel="noreferrer">
            <EditOutlined style={{ width: '25px', color: 'orange' }} />
          </Link>
        </Tooltip>
      }
    }

    if (action.name.indexOf("DELETE") !== -1) {
      return {
        key: 'delete',
        label:
          <Popconfirm
            title="Sure to delete?"
            placement="left"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={handleSingleDelete}
          >
            <Tooltip key={action.id} title={action.displayName}>
              <Link to={`${action.function}/${selectedRow?.id}`} target="_self" rel="noreferrer">
                <DeleteOutlined type='delete' style={{ width: '25px', color: 'red' }} />
              </Link>
            </Tooltip>
          </Popconfirm>

      }
    }
    return {}
  });



  return (
    <span>
      <Dropdown menu={{ items }} >
        <Space>
          <Button type='link'>
            acciones
            <DownOutlined />
          </Button>
        </Space>
      </Dropdown>
    </span>
  );
}

export default useActionMenu;