import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';



import errorService from "../../../services/error.service";

import { Alert, Form, Input, Modal, Select, Switch } from "antd";
import Messages from "../../../common/messages";
import DestinationService from "../../../services/destination.service";
import StateService from "../../../services/state.service";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const DestinationForm = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const toDestinationsLocation = location.state?.from?.pathname || "/destinations";

    const params = useParams();
    const [states, setStates] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);

    const [isOpen, setOpen] = useState(true);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {

        StateService.getAll().then(
            (response) => {
                setStates(response.data);
                setStateOptions(response.data.map(state => { return { label: state.name, value: state.id } }))
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );

        if (params.id) {
            setTitle('Edicion');
            setEditMode(true);
            DestinationService.getById(params.id).then(
                (response) => {
                    const destination = response.data;
                    form.setFieldsValue(destination);

                }, (error) => {
                    console.log(error)
                    setMessage(errorService.checkRequest(error));
                }
            );
        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toDestinationsLocation, { replace: true });
    }

    const handleSubmit = (destinationData) => {

        setMessage("");
        setLoading(true);


        if (editMode) {
            DestinationService.update(params.id, destinationData).then(
                (response) => {
                    setOpen(false);
                    navigate(toDestinationsLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {
            DestinationService.save(destinationData).then(
                (response) => {
                    setOpen(false);
                    navigate(toDestinationsLocation, { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const changeOrigin = (stateId) => {
        const nameFieldValue = form.getFieldValue('name')
        let value = ""
        const selectedState = states.filter(state => state.id === stateId)[0];
        console.log("El ID", stateId, "EL Valor del input", nameFieldValue, "state", selectedState)
        if (nameFieldValue) {
            const pos = nameFieldValue.indexOf('-')
            if (pos === -1) {
                value = selectedState.code + "-" + value;
            } else {
                value = selectedState.code + nameFieldValue.substring(pos);
            }
        } else {
            value = selectedState.code + "-";
        }
        form.setFieldValue("name", value)
    }

    const changeDestination = (stateId) => {
        const inputValue = form.getFieldValue('name')
        let value = ""
        const selectedState = states.filter(state => state.id === stateId)[0];
        console.log("El ID", stateId, "EL Valor del input", inputValue, "state", selectedState)
        if (inputValue) {
            let pos = inputValue.indexOf('-')
            if (pos === -1) {
                value += selectedState.code;
            } else {
                value = inputValue.substring(0, pos + 1) + selectedState.code;
            }
        } else {
            value = "-" + selectedState.code;
        }
        form.setFieldValue("name", value)
    }

    return (
        <Modal
            open={isOpen}
            onCancel={closeModal}
            onOk={handleOk}
            okButtonProps={{ disabled: loading }}
            title={`${title} de Destinos`}
        >
            <Form
                {...formItemLayout}
                form={form}
                labelAlign='left'
                validateMessages={Messages.common}
            >
                <Form.Item label='Nombre' name='name' required rules={[
                    {
                        required: true
                    }
                ]}>
                    <Input placeholder="Cochabamba-La Paz" />
                </Form.Item>
                <Form.Item label='Origen' name='origin' required rules={[
                    {
                        required: true
                    }
                ]}>
                    <Select options={stateOptions} onChange={changeOrigin} />

                </Form.Item>
                <Form.Item label='Destination' name='destination' required rules={[
                    {
                        required: true
                    },
                    {
                        message: "The Origin and destination should not be equals",
                        validator: (_, value) => {
                            const org = form.getFieldValue('origin');
                            console.log(org, value, org === value, org == value)
                            if (org !== value) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject('Should not be equals');
                            }
                        }
                    }
                ]}>
                    <Select options={stateOptions} onChange={changeDestination} />
                </Form.Item>
                <Form.Item label='Distancia' name='distance' tooltip={Messages.route.distance.tooltip}>
                    <Input placeholder="120" />
                </Form.Item>
                <Form.Item label='Estado' name='active' valuePropName="checked">
                    <Switch/>
                </Form.Item>
                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Form>
        </Modal>
    )
}
export default DestinationForm;