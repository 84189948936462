import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/country`;

const updateCountry = (userId, user) => {
  return axios.put(`${API_URL}/${userId}`, user, { headers: authHeader() });
};

const getCountry = (countryId) => {
  return axios.get(API_URL + "/" + countryId, { headers: authHeader() });
};

const saveCountry = (country) => {
  return axios.post(API_URL, country, { headers: authHeader() });
};

const getAllCountries = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const CountryService = {
  updateCountry,
  getCountry,
  saveCountry,
  getAllCountries
  
};

export default CountryService;
