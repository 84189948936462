import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/service-template`;

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getByTemplatePlan = (templatePlanId) => {
  return axios.get(`${API_URL}/${templatePlanId}`, { headers: authHeader() });
};

const save = (template) => {
  return axios.post(API_URL, template, { headers: authHeader() });
};

const update = (templateId, template) => {
  return axios.put(`${API_URL}/${templateId}`, template, { headers: authHeader() });
};

const deletePlan = (planId) => {
  return axios.delete(`${API_URL}/${planId}`, { headers: authHeader() });
};

const ServiceTemplateService = {
  getAll,
  save,
  update,
  deletePlan,
  getPlan: getByTemplatePlan
};

export default ServiceTemplateService;
