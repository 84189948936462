import { UploadOutlined } from "@ant-design/icons";
import { Button, Card, Upload, message } from "antd";
import { useState } from "react";
import authHeader from "../../../services/auth-header";

const ImportOperators = (props) => {

    const [fileList, setFileList] = useState([]);
    const cardTitle = props.cardTitle?props.cardTitle:"Import Data";

    let header = authHeader();
    delete header["Content-Type"];

    const uploadProps = {
        name: 'file',
        action: `http://localhost:8080/api/data/${props.importType}`,
        headers: header,
        accept: ".csv, .txt",
        onChange(info) {
            let newFileList = [...info.fileList];
            newFileList = newFileList.map(file => {
                file.name = file.response; return file;
            });
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
            setFileList(newFileList)
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            size: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    };

    return (
        <Card
            title={cardTitle}
            style={{
                width: 400,
            }}
        >
            <Upload {...uploadProps} fileList={fileList} maxCount={1}>
                <Button icon={<UploadOutlined />}>{props.title}</Button>
            </Upload>
        </Card>
    )
}

export default ImportOperators;