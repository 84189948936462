import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/provider`;

const update = (providerId, provider) => {
  return axios.put(`${API_URL}/${providerId}`, provider, { headers: authHeader() });
};

const findById = (providerId) => {
  return axios.get(`${API_URL}/${providerId}`, { headers: authHeader() });
};

const create = (provider) => {
  return axios.post(API_URL, provider, { headers: authHeader() });
};

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};


const ProviderService = {
  create,
  update,
  getAll,
  findById
};

export default ProviderService;
