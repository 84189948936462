import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/users`;

const updateUser = (userId, user) => {
  return axios.put(`${API_URL}/${userId}`, user, { headers: authHeader() });
};

const getUser = (userId) => {
  return axios.get(API_URL + "/" + userId, { headers: authHeader() });
};

const saveUser = (user) => {
  return axios.post(API_URL, user, { headers: authHeader() });
};

const getAllUsers = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getActions = (userId) => {
  return axios.get(API_URL+'/'+userId+"/actions", { headers: authHeader() });
};

const updateActions = (userId, actions) => {
  return axios.put(`${API_URL}/${userId}/actions`, actions, { headers: authHeader() });
};

const changePassword = (userId, newPassword) => {
  return axios.put(`${API_URL}/${userId}/password`, newPassword, { headers: authHeader() });
};

const getNotifications = (userId) => {
  return axios.get(`${API_URL}/${userId}/notifications`, { headers: authHeader() });
};
const UserService = {
  updateUser,
  getUser,
  saveUser,
  getAllUsers,
  getActions,
  updateActions,
  changePassword,
  getNotifications
};

export default UserService;
