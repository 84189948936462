import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Alert, message, Modal, Table, Transfer } from "antd";



import VehicleService from "../../services/vehicle.service";
import errorService from "../../services/error.service";
import OperatorService from "../../services/operator.service";
import Messages from "../../common/messages";




const VehicleOperator = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const toBusesLocation = location.state?.from?.pathname || "/vehicles";

    const params = useParams();
    const [messageApi, contextHolder] = message.useMessage();
    const [operators, setOperators] = useState([]);
    const [assignedOperators, setAssignedOperators] = useState([]);

    const [isOpen, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {

        OperatorService.getAll().then(
            (response) => {
                setOperators(response.data);
                console.log("All operators", operators)
            },
            (error) => {
                setErrorMessage(errorService.checkRequest(error));
            }
        );

        VehicleService.getAssigned(params.id).then(
            (response) => {
                setAssignedOperators(response.data);
                console.log("Asigned operators", assignedOperators)
            },
            (error) => {
                setErrorMessage(errorService.checkRequest(error));
            }
        );

    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toBusesLocation, { replace: true });
    }

    const handleSubmit = (operators) => {

        setErrorMessage("");
        setLoading(true);

        VehicleService.updateAssignedOperators(params.id, operators).then(
            (response) => {
                setOpen(false);
                navigate(toBusesLocation, { replace: true });
            },
            (error) => {
                setErrorMessage(errorService.checkRequest(error));
                setLoading(false);
            }
        )
    }

    const handleOk = () => {
        if (operators.length === 0 || assignedOperators.length === 0) {
            messageApi.open({
                type: 'error',
                content: Messages.vehicle.defaultOperator.notAssigned,                
                style: {
                    marginTop: '20vh',                    
                },
            });
            return;
        }
        let ops = operators.filter(op => { return assignedOperators.includes(op.id) })
        ops = ops.map((op, index) => {
            switch (index) {
                case 0: op.position = "Chofer"; break;
                case 1: op.position = "Ayudante"; break;
                default: op.position = "Ayudante"; break;
            }
            return op;
        })
        handleSubmit(ops)
    }


    const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
        <Transfer rowKey={(record) => record.id} {...restProps}>
            {({
                direction,
                filteredItems: originItems,
                onItemSelectAll,
                onItemSelect,
                selectedKeys: listSelectedKeys,

            }) => {
                const columns = direction === 'left' ? leftColumns : rightColumns;
                const rowSelection = {

                    onSelectAll(selected, selectedRows) {
                        console.log("onSelectAll", selectedRows)
                        if (selectedRows.length > 2) {
                            messageApi.open({
                                type: 'error',
                                content: Messages.vehicle.defaultOperator.maxAssignable,
                                className: 'custom-class',
                                style: {
                                    marginTop: '20vh',
                                },
                            });
                            return;
                        }
                        const diffKeys = selected
                            ? selectedRows.filter(item => !listSelectedKeys.includes(item))
                            : listSelectedKeys.filter(item => !selectedRows.include(item));
                        onItemSelectAll(diffKeys, selected);
                    },
                    onSelect(record, selected) {
                        onItemSelect(record.id, selected);
                    },
                    selectedRowKeys: listSelectedKeys,
                };
                return (
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={originItems}
                        size="small"
                        onRow={(record) => ({
                            onClick: () => {
                                setErrorMessage("")
                                onItemSelect(record.id, !listSelectedKeys.includes(record.id));
                            },
                        })}
                    />
                );
            }}
        </Transfer>
    );

    const operatorColumns = [
        { dataIndex: 'firstname', title: 'Nombre' },
        { dataIndex: 'lastname', title: 'Apellidos' },
        { dataIndex: 'driverLicense', title: 'Licencia' },
        { dataIndex: 'category', title: 'Categoria' }
    ]

    const assignedOperatorColumns = [
        { dataIndex: 'firstname', title: 'Nombre' },
        { dataIndex: 'lastname', title: 'Apellidos' },
        { dataIndex: 'driverLicense', title: 'Licencia' },
        { dataIndex: 'position', title: 'Cargo' }
    ]

    const onChange = (nextTargetKeys) => {
        const countAssigned = nextTargetKeys.length;
        if (countAssigned <= 3) {
            setAssignedOperators(nextTargetKeys);
        } else {
            setErrorMessage(Messages.vehicle.defaultOperator.maxAssignable)
        }
    };

    return (
        <>
            {contextHolder}
            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                title={`Vehicle Default Operators`}
                width={900}
            >

                <TableTransfer
                    dataSource={operators}
                    targetKeys={assignedOperators}
                    titles={["Operadores disponibles", "Operadores Assignados"]}
                    leftColumns={operatorColumns}
                    rightColumns={assignedOperatorColumns}
                    onChange={onChange}
                    showSearch={true}
                    filterOption={(inputValue, item) =>
                        item.firstname.indexOf(inputValue) !== -1 || item.lastname.indexOf(inputValue) !== -1
                    }
                />
                {errorMessage && (
                    <Alert
                        key={Math.random() * 16}
                        message="Request error"
                        description={errorMessage}
                        type="error"
                        closable
                    />
                )}

            </Modal>
        </>
    )
}
export default VehicleOperator;