import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/fuelTypeCost`;

const getAll = () => {
  return axios.get(API_URL,{ headers: authHeader() });
};

const get = (fuelTypeCostId) => {
  return axios.get(`${API_URL}/${fuelTypeCostId}`, { headers: authHeader() });
};

const save = (fuelTypeCost) => {
  return axios.post(API_URL, fuelTypeCost, { headers: authHeader() });
};


const del = (fuelTypeCostId) => {
  return axios.delete(`${API_URL}/${fuelTypeCostId}`, { headers: authHeader() });
};

const update = (fuelTypeCostId, fuelTypeCost) => {
  return axios.put(`${API_URL}/${fuelTypeCostId}`, fuelTypeCost, { headers: authHeader() });
};

const FuelTypeCostService = {
  getAll,  
  save,
  update,
  delete: del,
  get
};

export default FuelTypeCostService;
