import React, { useState, useEffect } from "react";


import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
import actionFactory from '../../common/ActionFactory'
import ButtonActionRow from "../../common/ButtonActionRow";
import { Badge, Collapse, Space, Table } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import FuelTypeService from "../../services/fuelType.service";

const { Panel } = Collapse;

const FuelTypes = () => {
  const [fuelTypes, setFueltypes] = useState([]);
  const [fuelTypeActions, setFuelTypeActions] = useState([]);
  const [fuelTypeCostActions, setFuelTypeCost] = useState([]);

  useEffect(() => {

    setFuelTypeActions(AuthService.getActionByGroup("FuelType", "table"));
    setFuelTypeCost(AuthService.getActionByGroup("Cost", "table"));

    FuelTypeService.getAll().then(
      (response) => {        
        setFueltypes(response.data);
      },
      (error) => {
        console.log("Error", error)
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);


  const columns = [    
    { title: 'Inicio', dataIndex: 'from', key: 'from' },
    { title: 'Fin', dataIndex: 'to', key: 'to' },
    { title: 'Costo', dataIndex: 'cost', key: 'cost' },
    {
      title: 'Estado', dataIndex: 'active', key: 'active',
      render: (e, record) => (
        <Badge
          text={record.active}
          count={
            record.active ? (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
              <ExclamationCircleOutlined style={{ color: 'red' }} />
            )
          } />)
    },
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <Space size="middle">
          {
            fuelTypeCostActions.map((action, index) => {
              return actionFactory.getAction(action, { id: record.id })
            })
          }
        </Space>
      ),
    }
  ]

  const genFuelTypeActions = (fuelType) => {
    return fuelTypeActions.map(action => {
      return actionFactory.getAction(action, { id: fuelType.id,parentId:fuelType.id, parentName:fuelType.name })
    })
  }

  const drawAccordion = () => {
    return <Collapse accordion defaultActiveKey={[`accordion-0`]} >
      {
        fuelTypes.map((fuelType, index) => {
          return (
            <Panel
              header={`${fuelType.name}`}
              key={`accordion-${index}`}
              extra={genFuelTypeActions(fuelType)}>
              <ButtonActionRow group="Cost" extraParams={{parentId:fuelType.id, parentName:fuelType.name}}/>
              <Table
                columns={columns} dataSource={fuelType.costs}
              />
            </Panel>
          )
        })
      }
    </Collapse>

  }


  return (
    <>
      <ButtonActionRow group="FuelType" />
      {drawAccordion()}
    </>
  );
};

export default FuelTypes;
