import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import VehicleService from "../../services/vehicle.service";
import errorService from "../../services/error.service";
import { Alert, DatePicker, Form, Input, InputNumber, Modal, Select, Space, Switch, Typography } from "antd";
import Messages from "../../common/messages";
import ProviderService from "../../services/provider.service";
import JourneyService from "../../services/journey.service";
import Tracker from "../../common/Constants";
import dayjs from "dayjs";



const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const OilForm = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const toBusesLocation = location.state?.from?.pathname || "/vehicles";


    const params = useParams();
    const [journeys, setJourneys] = useState([]);
    const [providers, setProviders] = useState([]);
    const [operators, setOperators] = useState([]);
    const [costs, setCosts] = useState([]);


    const [isOpen, setOpen] = useState(true);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const [moneyCost, setMoneyCost] = useState(0);
    const [tripDistance, setTripDistance] = useState(0);


    useEffect(() => {
        form.setFieldsValue({
            dateTime: dayjs()
        });
        VehicleService.getJourneys(params.id).then(
            (response) => {
                console.log(response.data)
                setJourneys(response.data.map(journey => {
                    journey.label = `${journey.code}-[${journey.travelDate}:${journey.travelTime}]`
                    journey.value = journey.id
                    return journey
                }));
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );

        ProviderService.getAll().then(
            (response) => {
                const provs = response.data;
                setProviders(provs);
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );
        VehicleService.getCostsByVehicle(params.id).then(
            (response) => {
                setCosts(response.data);
                console.log("costos", response.data, costs)
                form.setFieldsValue({
                    fuelTypeCostId: response.data[0].id
                });
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toBusesLocation, { replace: true });
    }

    const handleSubmit = (oilData) => {

        setMessage("");
        setLoading(true);

        VehicleService.addOil(params.id, oilData).then(
            (response) => {
                setOpen(false);
                navigate(toBusesLocation, { replace: true });
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
                setLoading(false);
            }
        )
    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                console.log("the values", values)
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const updateOperators = (journeyId) => {        
        setTripDistance(journeys.find(j=>j.id===journeyId).route.distance)
        form.setFieldValue("journeyId", journeyId)
        JourneyService.getAssigned(journeyId).then(
            (response) => {
                console.log("Journey Operators", response.data)
                setOperators(response.data.map(op => {
                    op.label = `${op.firstname}-${op.lastname}`;
                    op.value = op.id;
                    return op;
                }));
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
                setLoading(false);
            }
        )
        
    }

    const adjustCost = (value) => {
        const selectedCostID = form.getFieldValue('fuelTypeCostId');
        const cost = costs.find(cost => cost.id === selectedCostID);        
        setMoneyCost(value * cost.cost);
    }

    return (
        <>
            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                title={Messages.vehicle.oil.modal.title}
            >
                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    labelAlign='left'
                    validateMessages={Messages.common}
                >
                    <Form.Item label={Messages.vehicle.oil.modal.date} name='dateTime' required rules={Tracker.Validation.requiredOnly}>
                        <DatePicker showTime minuteStep={15} format={"YYYY-MM-DD H:mm"} />
                    </Form.Item>
                    <Form.Item label={Messages.vehicle.oil.modal.reference.label} name='reference' tooltip={Messages.vehicle.oil.modal.reference.tooltip}>
                        <Input placeholder="125254587" />

                    </Form.Item>
                    <Form.Item label={Messages.vehicle.oil.modal.fuelCost} name='fuelTypeCostId' required rules={Tracker.Validation.requiredOnly}>
                        <Select options={costs} fieldNames={{ label: "cost", value: "id" }} />
                    </Form.Item>
                    <Form.Item label={Messages.vehicle.oil.modal.quantity} name='liters' required rules={Tracker.Validation.requiredOnly}>
                        <Space direction="vertical" size="small">
                            <InputNumber placeholder="100" addonAfter="lts" onChange={adjustCost} />
                            <Typography>
                                <pre>Money Cost: {moneyCost}</pre>
                            </Typography>
                        </Space>
                    </Form.Item>
                    <Form.Item label={Messages.vehicle.oil.modal.isFull} name='isFull' valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Form.Item label={Messages.vehicle.oil.modal.provider} name='providerId' required rules={Tracker.Validation.requiredOnly}>
                        <Select options={providers} fieldNames={{ label: "name", value: "id" }} />
                    </Form.Item>
                    <Form.Item label={Messages.vehicle.oil.modal.journey} name='journeyId' required rules={Tracker.Validation.requiredOnly}>
                        <Select options={journeys} style={{ width: '150%' }} onSelect={updateOperators} />
                        <Typography>
                            <pre>Recorrido: {tripDistance}</pre>
                        </Typography>
                    </Form.Item>
                    <Form.Item label={Messages.vehicle.oil.modal.operator} name='operatorId' required rules={Tracker.Validation.requiredOnly}>
                        <Select options={operators} />
                    </Form.Item>

                    {message && (
                        <Alert
                            showTime={1}
                            message="Request error"
                            description={message}
                            type="error"
                            closable
                        />
                    )}
                </Form>
            </Modal>
        </>
    )
}
export default OilForm;