import axios from "axios";
import Tracker from "../common/Constants";
import EventBus from "../common/EventBus";

const API_URL = `${Tracker.backend.host}/auth/`;

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (auth) => {
  console.log("base url", Tracker.backend.host, process.env.REACT_APP_BACKEND_HOST)
  return axios
    .post(API_URL + "signin", auth)
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (isValidToken(user?.accessToken)) {
    return user;
  }
  return false;
};

const getActionByGroup = (group, groupType) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const actions = userData.roles.filter(r => r.action.group === group && r.action.groupType === groupType).map(rol => rol.action);
  return actions;
}

const isValidToken = (token) => {
  if (!token) {  
    return false;
  }

  const decoded = parseJWT(token);
  if (Date.now() >= decoded.exp * 1000) {
    EventBus.dispatch("logout");
    return false;
  }
  return true;
}

const parseJWT = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);

}

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getActionByGroup
};

export default AuthService;
