const common = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
const vehicle = {
  defaultOperator: {
    maxAssignable: "Only 3 operators are allowed",
    notAssigned: "There is no Operator selected, please select max 3 or min 1 Operator"
  },
  oil: {
    modal: {
      title: "Add Oil",
      provider: "Proveedor",
      date: "Fecha y hora",
      reference: {
        label: "Referencia",
        tooltip: "Referencia de la carga, ejemplo: Nro Ticket/Nro factura"
      },
      isFull: "Tanque lleno?",
      operator: "Operador",
      quantity: "Carga",
      journey: "Viaje",
      fuelCost: "Costo unitario"
    }
  },
  journey: {
    maxAssignable: "Only 3 operators are allowed",
    notAssigned: "There is no Operator selected, please select max 3 or min 1 Operator",
    modal: {
      title: "Journey Operators"
    }
  },
  maintenance: {
    notFound: "No se asigno un plan de mantenimientos, seleccione uno de la lista para asignarlo?",
    load: "Ver planes"
  }
}

const journey = {
  maxAssignable: "Only 3 operators are allowed",
  notAssigned: "There is no Operator selected, please select max 3 or min 1 Operator",
  modal: {
    title: "Journey Operators"
  },
  vehicle: {
    tooltip: "Selecting vehicle will load their assigned operators, this will be assigned to the journey, to change it please update operators form the journeys table"
  }
}
const route = {
  distance: {
    tooltip: "The approximated distance in Km, by default 0"
  }
}

const incident = {
  form: {
    image: {
      label: 'Imagen',
      tooltip: {
        upload: "Seleccione una imagen",
        remove: "Para cambiar la imagen, por favor pase el mouse sobre la imagen y presione sobre el icono de basurero"
      }
    }
  }
}

const tire = {
  assign: {
    vehicle: {
      label: "Vehiculo",
      placeholder: "Seleccione un vehiculo para ver el modelo"
    },
    model: {
      label: "Modelo",
      placeholder: "Seleccione un vehiculo para ver el modelo",
      notAssigned: "Modelo No asignado"
    }
  }
}
const TrackerMessages = {
  common,
  vehicle,
  route,
  journey,
  incident,
  tire
}
export default TrackerMessages;