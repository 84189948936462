import axios from "axios";
import Tracker from "../common/Constants";

export default axios.create({
    baseURL: Tracker.backend.host
});

export const axiosPrivate = axios.create({
    baseURL: Tracker.backend.host,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});