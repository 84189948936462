import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/store`;

const update = (storeId, store) => {
  return axios.put(`${API_URL}/${storeId}`, store, { headers: authHeader() });
};

const findById = (storeId) => {
  return axios.get(`${API_URL}/${storeId}`, { headers: authHeader() });
};

const save = (store) => {
  return axios.post(API_URL, store, { headers: authHeader() });
};

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};


const StoreService = {
  save,
  update,
  getAll,
  findById
};

export default StoreService;
