import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom'


const IconAction = (props) => {    
    return (
        <Tooltip key={props.id} title={props.alt}>
            <Link to={props.action} target="_self" rel="noreferrer">            
                <img src={props.img} alt={props.alt} width="25px" height="25px"></img>
            </Link>
        </Tooltip>
    )
}

export default IconAction;