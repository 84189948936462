import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/buses`;

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getBus = (busId) => {
  return axios.get(`${API_URL}/${busId}`, { headers: authHeader() });
};

const save = (bus) => {
  return axios.post(API_URL, bus, { headers: authHeader() });
};


const deleteBus = (busId) => {
  return axios.delete(`${API_URL}/${busId}`, { headers: authHeader() });
};

const update = (busId, bus) => {
  return axios.put(`${API_URL}/${busId}`, bus, { headers: authHeader() });
};

const getAssigned = (vehicleId) => {
  return axios.get(`${API_URL}/${vehicleId}/operators`, { headers: authHeader() });
};

const updateAssignedOperators = (vehicleId, operators) => {
  return axios.put(`${API_URL}/${vehicleId}/operators`, operators, { headers: authHeader() });
};

const getJourneys = (vehicleId) => {
  return axios.get(`${API_URL}/${vehicleId}/journeys`, { headers: authHeader() });
};

const getCostsByVehicle = (vehicleId) => {
  return axios.get(`${API_URL}/${vehicleId}/costs`, { headers: authHeader() });
};

const addOil = (vehicleId, fuelingData) => {
  return axios.post(`${API_URL}/${vehicleId}/add-oil`, fuelingData, { headers: authHeader() });
};

const getMaintenacePlan = (vehicleId) => {
  return axios.get(`${API_URL}/${vehicleId}/maintenance-plan`,  { headers: authHeader() });
}

const getTires = (vehicleId) => {
  return axios.get(`${API_URL}/${vehicleId}/tires`,  { headers: authHeader() });
}

const updateMaintenancePlan = (vehicleId, planId) => {
  return axios.put(`${API_URL}/${vehicleId}/maintenance-plan`, {vehicleId, planId}, { headers: authHeader() });
}

const assignChassisModel = (vehicleId, chassisModelId) =>{
  return axios.post(`${API_URL}/${vehicleId}/add-chassis`, {id:chassisModelId}, { headers: authHeader() });
}
const VehicleService = {
  getAll,
  save,
  update,
  deleteBus,
  getBus,
  getAssigned,
  updateAssignedOperators,
  getJourneys,
  getCostsByVehicle,
  addOil,
  getMaintenacePlan,
  updateMaintenancePlan,
  getTires,
  assignChassisModel
};

export default VehicleService;
