import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import VehicleService from "../../services/vehicle.service";
import PlanService from "../../services/planTemplate.service";
import errorService from "../../services/error.service";
import VehicleTypeService from "../../services/vehicle-type.service";
import { Alert, Button, Card, DatePicker, Divider, Form, Input, InputNumber, Modal, Select, Steps } from "antd";
import Messages from "../../common/messages";
import FuelTypeService from "../../services/fuelType.service";
import dayjs from "dayjs";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const BusForm = () => {

    let navigate = useNavigate();

    const params = useParams();

    const [types, setTypes] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [plans, setPlans] = useState([]);


    const [isOpen, setOpen] = useState(true);
    const [mandatoryForm] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    const Mandatory = () => {
        return (

            <> <Form.Item label='Placa' name='plate' required rules={[
                {
                    required: true
                }
            ]}>
                <Input placeholder="3101-ptr" />
            </Form.Item>
                <Form.Item label='Chasis' name='chassis' required rules={[
                    {
                        required: true
                    }
                ]}>
                    <Input placeholder="AJ1524SD54587" />

                </Form.Item>
                <Form.Item label='Nro Motor' name='engine' required rules={[
                    {
                        required: true
                    }
                ]}>
                    <Input placeholder="125254587" />

                </Form.Item>
                <Form.Item label='Marca' name='brand' required rules={[
                    {
                        required: true
                    }
                ]}>
                    <Input placeholder="Renault" />
                </Form.Item>
                <Form.Item label='Ano' name='year' required rules={[
                    { required: true }
                ]}>
                    <InputNumber min={new Date().getFullYear() - 50} max={new Date().getFullYear() + 2} value={new Date().getFullYear() - 10} />
                </Form.Item>
                <Form.Item label='Modelo' name='model' required rules={[
                    {
                        required: true
                    }
                ]}>
                    <Input placeholder="Semicama" />
                </Form.Item>

                <Form.Item label='Interno' name='code' required rules={[
                    {
                        required: true
                    }
                ]}>
                    <Input placeholder="001" />
                </Form.Item>
                <Form.Item label='Tipo' name='type' required rules={[
                    {
                        required: true
                    }
                ]}>
                    <Select >
                        <Select.Option value="-1">Seleccione el tipo</Select.Option>
                        {types.map((item, index) => (
                            <Select.Option key={index} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label='Combustible' name='fuelType' required rules={[
                    {
                        required: true
                    }
                ]}>
                    <Select options={fuelTypes} fieldNames={{ label: "name", value: "id" }} />
                </Form.Item>
            </>
        )
    }
    const Optional = () => {
        return (
            <>
                <Divider>Seguro </Divider>
                <Form.Item label='Empresa Aseguradora' name={['details', 'insurranceCompany']} >
                    <Input placeholder="Alianza S.A." />
                </Form.Item>
                <Form.Item label='Fecha Inicio' name={['details', 'insuranceStart']}>
                    <DatePicker placeholder="10-02-2022" />
                </Form.Item>
                <Form.Item label='Fecha Fin' name={['details', 'insuranceEnd']}>
                    <DatePicker placeholder="09-02-2024" />
                </Form.Item>
                <Divider>Tarjeta de Operaciones</Divider>
                <Form.Item label='Inicio' name={['details', 'operationCardStart']}>
                    <DatePicker placeholder="10-12-2023" />
                </Form.Item>
                <Form.Item label='Fin' name={['details', 'operationCardEnd']}>
                    <DatePicker placeholder="10-12-2024" />
                </Form.Item>
            </>
        )
    }

    const steps = [
        {
            title: 'Informacion Obligatoria',
            content: <Mandatory />,
        },
        {
            title: 'Informacion Opcional',
            content: <Optional />,
        }
    ];


    const [current, setCurrent] = useState(0);
    const next = () => {
        let nextStep = current;
        if (current === 0) {
            mandatoryForm
                .validateFields()
                .then((values) => {
                    nextStep++;
                    if (current >= steps.length-1) {
                        nextStep = 0;
                    }
                    setCurrent(nextStep);
                })
                .catch((info) => {
                    setMessage("Debe llenar todos los campos obligatorios antes de pasar al siguiente formulario")
                });
        } else {
            nextStep++;
            if (current >= steps.length-1) {
                nextStep = 0;
            }
            console.log("steps:", steps.length + " current:", current + " Setting nextStep to :", nextStep)
            setCurrent(nextStep);

        }
    };
    const prev = () => {
        setCurrent(current <= 0 ? setCurrent(0) : setCurrent(current - 1));
    };
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    useEffect(() => {

        VehicleTypeService.getAll().then(
            (response) => {
                setTypes(response.data);
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );

        FuelTypeService.getAll().then(
            (response) => {
                setFuelTypes(response.data);
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );

        PlanService.getAll().then(
            (response) => {
                setPlans(response.data);
            },
            (error) => {
                setMessage(errorService.checkRequest(error));
            }
        );

        if (params.id) {
            setTitle('Edicion');
            setEditMode(true);
            VehicleService.getBus(params.id).then(
                (response) => {
                    const bus = response.data;
                    if (bus.details.insuranceStart) {
                        bus.details.insuranceStart = dayjs(bus.details.insuranceStart);
                    }
                    if (bus.details.insuranceEnd) {
                        bus.details.insuranceEnd = dayjs(bus.details.insuranceEnd);
                    }
                    if (bus.details.operationCardStart) {
                        bus.details.operationCardStart = dayjs(bus.details.operationCardStart);
                    }
                    if (bus.details.operationCardEnd) {
                        bus.details.operationCardEnd = dayjs(bus.details.operationCardEnd);
                    }
                    mandatoryForm.setFieldsValue(bus);

                }, (error) => {
                    console.log(error)
                    setMessage(errorService.checkRequest(error));
                }
            );
        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate("/vehicles", { replace: true });
    }

    const handleSubmit = (vehicleMandatoryData) => {

        setMessage("");
        setLoading(true);


        if (editMode) {
            VehicleService.update(params.id, vehicleMandatoryData).then(
                (response) => {
                    setOpen(false);
                    navigate("/vehicles", { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {
            VehicleService.save(vehicleMandatoryData).then(
                (response) => {
                    setOpen(false);
                    navigate("/vehicles", { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        const values = mandatoryForm.getFieldsValue(true)

        console.log("the values", values)
        handleSubmit(values);
    }

    const onSelectStep = (value) => {
        console.log('onChange:', value);
        setCurrent(value);
    };


    return (
        <>
            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                title={`${title} de bus`}
                footer={[

                    <Button type="primary" onClick={next} disabled={current >= steps.length}>
                        Next
                    </Button>
                    ,
                    <Button onClick={handleOk}>Guardar</Button>
                    ,
                    <Button onClick={prev} disabled={current <= 0}>
                        Previous
                    </Button>


                ]}
            >

                <Form
                    {...formItemLayout}
                    size='medium'
                    form={mandatoryForm}
                    labelAlign='left'
                    validateMessages={Messages.common}
                    preserve={true}
                >
                    <Steps current={current} items={items} onChange={onSelectStep} />
                    <Card style={{margin:"1px"}}>{steps[current].content}</Card>

                    {message && (
                        <Alert
                            message="Request error"
                            description={message}
                            type="error"
                            closable
                        />
                    )}
                </Form>
            </Modal>
        </>
    )
}
export default BusForm;