import React, { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import { Avatar, Button, Card, Descriptions, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import authHeader from "../../services/auth-header";
import OrganizationService from "../../services/organization.service";
import UserService from "../../services/user.service";
import ImportOperators from "./import/ImportOperators";


const Profile = () => {

  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState({});
  const [org, setOrg] = useState({});
  const currentUser = AuthService.getCurrentUser();

  let header = authHeader();
  delete header["Content-Type"];
  useEffect(() => {
    UserService.getUser(currentUser.id)
      .then((response) => {
        setUser(response.data);
        OrganizationService.get(response.data.orgId).then((response) => {
          const organization = response.data;
          console.log("loading org", organization.legalUser === currentUser.id)
          if (organization.legalUser === currentUser.id) {
            setIsAdmin(true);
            setOrg(organization)
          }
        }).catch(error => {
          console.log("error cargando organizacion", error)
        });

      })
      .catch(error => { console.log(error) });

  }, []);

  return (
    <>
      <Space>
        <Card title="User information">
          <Space>
            <Avatar size={124}>
              <UserOutlined style={{ fontSize: 60 }} />
            </Avatar>
            <Descriptions column={1}>
              <Descriptions.Item label="Nombres">{user.name}</Descriptions.Item>
              <Descriptions.Item label="Apellidos">{user.lastname}</Descriptions.Item>
              <Descriptions.Item label="Username">{user.username}</Descriptions.Item>
              <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
            </Descriptions>
          </Space>
        </Card>
        {isAdmin && (
          <Card title="Organization Information">
            <Space>
              <Descriptions column={1}>
                <Descriptions.Item label="Nombre">{org.name}</Descriptions.Item>
                <Descriptions.Item label="Direccion">{org.address}</Descriptions.Item>
                <Descriptions.Item label="Pais">{org.country}</Descriptions.Item>
                <Descriptions.Item label="Registro">{org.activationDate}</Descriptions.Item>
              </Descriptions>
              <Descriptions column={1}>
                <Descriptions.Item label="Licencia">{org.license}</Descriptions.Item>
                <Descriptions.Item label="Next Payment">{org.nextPayment}</Descriptions.Item>
                <Descriptions.Item label="Upgrade">{org.license === "PERSONAL" ? <Button type="primary">Upgrade</Button> : ""}</Descriptions.Item>
              </Descriptions>
            </Space>
          </Card>
        )}
      </Space>
      {isAdmin && (
        <Space>
          <ImportOperators importType="operators" title="Upload Operators file" />
          <ImportOperators importType="vehicles" title="Upload Vehicles file" />
        </Space>
      )}
    </>
  );
};

export default Profile;
