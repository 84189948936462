import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Form, Input, Modal, Select, Table } from "antd";

import errorService from "../../../../services/error.service";
import ServiceTemplateService from "../../../../services/serviceTemplate.service";

import Messages from "../../../../common/messages";
import Tracker from "../../../../common/Constants";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };
const { Option } = Select;
const ServiceForm = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const plansURL = location.state?.from?.pathname || "/services";

    const params = useParams();

    const [isOpen, setOpen] = useState(true);
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    useEffect(() => {

        if (params.id) {
            ServiceTemplateService.getByTemplatePlan(params.id).then(
                (response) => {
                    form.setFieldValues(response.data);
                }, (error) => {
                    console.log(error)
                    setMessage(errorService.checkRequest(error));
                }
            );
        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(plansURL, { replace: true });
    }

    const handleSubmit = (serviceData) => {

        setMessage("");
        setLoading(true);


        if (params.id) {
            ServiceTemplateService.update(params.id, serviceData).then(
                (response) => {
                    setOpen(false);
                    navigate(plansURL, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {
            ServiceTemplateService.save(serviceData).then(
                (response) => {
                    setOpen(false);
                    navigate(plansURL, { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    const serviceTypes = [{ label: "Tiempo", value: "timer" }, { label: "Kilometros", value: "mileage" }]
    const timeType = (
        <Select defaultValue="dias">
          <Option value="days">Dias</Option>
          <Option value="months">Meses</Option>
          <Option value="years">Años</Option>
          
        </Select>
      );

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
            >
            </div>

            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                title={`Plan de mantenimientos`}
            >

                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    labelAlign='left'
                    validateMessages={Messages.common}
                >
                    <Form.Item label='Nombre' name='name' required rules={Tracker.Validation.requiredOnly}>
                        <Input placeholder="Nombre del servicio" />
                    </Form.Item>
                    <Form.Item label='Tipo' name='type' required rules={Tracker.Validation.requiredOnly}>
                        <Select options={serviceTypes} />
                    </Form.Item>
                    <Form.Item label='Tiempo' name='timer' required rules={Tracker.Validation.requiredOnly}>
                        <Input placeholder="Cada cuanto tiempo necesita revision" addonAfter={timeType}/>
                    </Form.Item>
                    <Form.Item label='Kilometros' name='mileage' required rules={Tracker.Validation.requiredOnly}>
                        <Input placeholder="Cada cuantos kilometros necesita revision" />
                    </Form.Item>
                </Form>
                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Modal>
        </>
    )
}
export default ServiceForm;