import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Alert, Col, Form, List, Row, Select, Space, message as popMessage } from "antd";

import Messages from "../../../common/messages";
import VehicleService from "../../../services/vehicle.service";
import errorService from "../../../services/error.service";
import ChassisModelService from "../../../services/chassisModel.service";
import ModelDrawer from "./ModelDrawer";
import TireService from "../../../services/tire.service";
import { DraggableTireItem } from "./DraggableTireItem";

const AssignTire = () => {

  const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 24 } };
  const [mandatoryForm] = Form.useForm();
  const [vehicles, setVehicles] = useState([]);
  const [models, setModels] = useState([]);
  const [assignedTires, setAssignedTires] = useState([]);
  const [availableTires, setAvailableTires] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [message, setMessage] = useState();

  const [messageApi, contextHolder] = popMessage.useMessage()

  useEffect(() => {
    VehicleService.getAll().then(
      (response) => {
        setVehicles(response.data.map(vehicle => {
          vehicle.value = vehicle.id;
          vehicle.label = `code:${vehicle.code} plate:${vehicle.plate}`;
          return vehicle;
        }));
      },
      (error) => {
        setMessage(errorService.checkRequest(error));
      }
    );

    ChassisModelService.getAll().then(
      (response) => {
        let models = response.data.map(model => {
          model.value = model.id;
          model.label = `${model.name}`;
          return model;
        });
        models.splice(0, 0, { value: -1, label: Messages.tire.assign.model.notAssigned });
        setModels(models);
      },
      (error) => {
        setMessage(errorService.checkRequest(error));
      }
    );

    TireService.getAvailable().then(
      (response) => {
        setAvailableTires(response.data);
        console.log("Available tires", availableTires)
      },
      (error) => {
        setMessage(errorService.checkRequest(error));
      }
    );
  }, []);

  const onVehicleChange = (vehicleId) => {
    const vehicle = vehicles.find((currentValue, index, arr) => {
      return currentValue.id === vehicleId;
    })
    console.log("Selected Vehicle ", vehicle)
    mandatoryForm.setFieldValue("model", vehicle.chassisModel === null ? -1 : vehicle.chassisModel.id);

    VehicleService.getTires(vehicleId).then(
      (response) => {
        setAssignedTires(response.data);
        console.log("assigned tires", assignedTires)
      },
      (error) => {
        setMessage(errorService.checkRequest(error));
      }
    );
    if(vehicle.chassisModel){
      getChassisModelItems(vehicle.chassisModel.id)
    }else{
      setSelectedModel(null);
    }

  }

  const onSelectModel = (modelId) => {
    if (modelId === -1) {
      messageApi.info('Seleccione un modelo valido para ver los items');
      setSelectedModel(null);
    } else {
      getChassisModelItems(modelId);
    }
  }

  const getChassisModelItems = (modelId) =>{
    ChassisModelService.getItems(modelId).then(
      (response) => {
        const model = response.data;
        setSelectedModel(model);
      },
      (error) => {
        setMessage(errorService.checkRequest(error));
      }
    );
  }

  const onDragHandler = (item, monitor) => {
    console.log("new Item ", item, "current items ", assignedTires, "dropResult", monitor)
    const assigned = [...assignedTires];
    assigned.push(item)
    setAssignedTires(assigned);
    console.log("new assigned items ", assignedTires)
  }

  return (
    <Form
      {...formItemLayout}
      size='medium'
      form={mandatoryForm}
      labelAlign='left'
      validateMessages={Messages.common}
      preserve={true}
    >
      {contextHolder}
      <DndProvider backend={HTML5Backend}>
        <Row gutter={[8, 8]}>
          <Col span={12} className="bus" flex={"flex"}>
            <Space direction="vertical">
              <Form.Item label={Messages.tire.assign.vehicle.label}>
                <Select
                  showSearch
                  placeholder={Messages.tire.assign.vehicle.placeholder}
                  options={vehicles}
                  onSelect={onVehicleChange}
                  style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label={Messages.tire.assign.model.label} name={"model"}>
                <Select
                  placeholder={Messages.tire.assign.model.placeholder}
                  options={models}
                  onSelect={onSelectModel}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <ModelDrawer view="horizontal" model={selectedModel} assignedTires={assignedTires} />
            </Space>
          </Col>
          <Col span={12} className="bus">
            <List
              header="Llantas Disponibles"
              itemLayout="horizontal"
              dataSource={availableTires}
              renderItem={(item, index) => (
                <DraggableTireItem item={item} index={index} onDragHandler={onDragHandler} />
              )}
            />
          </Col>
        </Row>
      </DndProvider>
      {message && (
        <Alert
          message="Request error"
          description={message}
          type="error"
          closable
        />
      )}
    </Form>
  );
};

export default AssignTire;
