import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/roles`;


const saveUser = (user) => {
  return axios.post(API_URL, user, { headers: authHeader() });
};

const getAll = () => {
  return axios.get(API_URL , { headers: authHeader() });
};

const RoleService = {
  saveUser,
  getAll,
};

export default RoleService;
