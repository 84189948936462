import React, { useState, useEffect } from "react";
import ButtonActionRow from "../../../common/ButtonActionRow";
import useDataTable from "../../../hooks/useDataTable";
import TireService from "../../../services/tire.service";
import errorService from "../../../services/error.service";
import AuthService from "../../../services/auth.service";


const Tires = () => {
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    TireService.getAll().then(
      (response) => {
        setData({ content: response.data });
        console.log("Tires data", data)
      },
      (error) => {
        setMessage(errorService.checkRequest(error));
      }
    );
  }, []);

  const columns = [
    {
      title: 'Ancho',
      dataIndex: 'broad',
      key: 'broad'
    },
    {
      title: 'Alto',
      dataIndex: 'high',
      key: 'high',
    },
    {
      title: 'Diametro',
      dataIndex: 'diameter',
      key: 'diameter',
    },
    {
      title: 'Fecha de compra',
      dataIndex: 'buyDate',
      key: 'buyDate',
    },
    {
      title: 'PresionMaxima',
      key: 'loadLimit',
      dataIndex: 'loadLimit'
    },
    {
      title: 'Recorrido',
      key: 'distanceTraveled',
      dataIndex: 'distanceTraveled'
    },
  ];

  const handleDelete = (row) => {
    TireService.deleteIncident(row.id).then(
      (response) => {

      }
    ).catch((error) => { })
  }

  const {
    DataTable,
    hasSelected,
    currentPage,
    pageSize,
    resetPagination,
  } = useDataTable({
    columns,
    dataSource: data,
    actions: AuthService.getActionByGroup("Tire", "table"),
    handleSingleDelete: handleDelete
  });
  return (
    <>
      <ButtonActionRow group="Tire" />
      <DataTable />
    </>
  );
};

export default Tires;
