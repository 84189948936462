import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
const API_URL = `${Tracker.backend.host}/tire`;

const save = (tire) => {
  return axios.post(API_URL, tire, { headers: authHeader() });
};

const findById = (tireId) => {
  return axios.get(`${API_URL}/${tireId}`, { headers: authHeader() });
};

const update = (tireId, tire) => {
  return axios.put(`${API_URL}/${tireId}`, tire, { headers: authHeader() });
};



const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getAvailable = () => {
  return axios.get(`${API_URL}/available`, { headers: authHeader() });
};


const TireService = {
  save,
  update,
  getAll,
  findById,
  getAvailable 
};

export default TireService;
