import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import errorService from "../../../services/error.service";
import { Alert, Button, Collapse, Descriptions, Form, Modal, Popconfirm, Radio, Space, Table } from "antd";

import Messages from "../../../common/messages";
import PlanTemplateService from "../../../services/planTemplate.service";
import VehicleService from "../../../services/vehicle.service";

const { Panel } = Collapse;
const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 10 } };

const VehicleMaintenance = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const toPlansLocation = location.state?.from?.pathname || "/vehicles";

    const params = useParams();
    const [form] = Form.useForm();


    const [isOpen, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);


    const [planTemplates, setPlanTemplates] = useState([]);
    const [isAssignedPlan, setIsAssignedPlan] = useState(false);
    const [assignedPlan, setAssignedPlan] = useState({});

    const [planId, setPlanId] = useState(-1);

    useEffect(() => {

        if (params.id) {
            VehicleService.getMaintenacePlan(params.id)
                .then(response => {
                    setAssignedPlan(response.data)                    
                    if (response.data.id != null) {
                        setIsAssignedPlan(true)
                    }
                })
                .catch();

            PlanTemplateService.getAll().then(
                (response) => {
                    const services = response.data;
                    setPlanTemplates(services);
                }, (error) => {
                    console.log(error)
                    setMessage(errorService.checkRequest(error));
                }
            );
        } else {
            console.log("No hay bus para buscar planes")
        }
    }, []);


    const closeModal = () => {
        if (planId !== -1) {
            setOpenConfirm(true);
        } else {
            setOpen(false)
            navigate(toPlansLocation, { replace: true });
        }

    }

    const saveAssignedPlan = () => {

        setMessage("");
        setLoading(true);


        if (planId !== -1) {

            VehicleService.updateMaintenancePlan(params.id, planId).then(
                (response) => {
                    closeDialogs()
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                    setOpenConfirm(false);
                }
            )
        }
    }



    const drawServices = () => {
        const columns = [
            {
                title: 'Nombre',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Recurrencia',
                dataIndex: 'recurrenceType',
                key: 'recurrenceType'
            },
            {
                key: 'timeAprox',
                title: 'Por Tiempo',
                dataIndex: 'timeApprox',
                render: (text, record) => (record.timer + ' ' + record.timeType)
            },
            {
                key: 'mileage',
                title: 'Por Recorrido',
                dataIndex: 'mileage'
            }
        ];


        const onSelectPlanRadioButton = (e) => {
            e.stopPropagation();
            setPlanId(e.target.value);
        };
        return (
            <Radio.Group  value={planId}>
                <Collapse accordion defaultActiveKey={[`accordion-0`]} >
                    {
                        planTemplates.map((planTemplate, index) => {
                            return (
                                <Panel header={planTemplate.name}
                                    key={`accordion-${index}`}
                                    extra={<Radio value={planTemplate.id} onChange={(event) => { event.stopPropagation();onSelectPlanRadioButton(event)}}/>}>

                                    <Table
                                        key={`services${planTemplate.id}-${index}`}
                                        columns={columns}
                                        dataSource={planTemplate.services}
                                        fbordered />

                                </Panel>
                            )
                        })
                    }
                </Collapse>
            </Radio.Group>
        )
    }
    

    const confirm = (e) => {
        saveAssignedPlan();
    };
    const closeDialogs = (e) => {
        setOpen(false)
        navigate(toPlansLocation, { replace: true });
        setOpenConfirm(false);
    };

    const drawAssignedPlan = () => {
        return (
            <Collapse accordion defaultActiveKey={[`assignedPlanService-0`]} >
                {
                    assignedPlan.assignedServices.map((assignedService, index) => {
                        return (
                            <Panel header={assignedService.serviceName}
                                key={`assignedPlanService-${index}`}>
                                <Descriptions bordered column={1} key={`description-${index}`}>
                                    <Descriptions.Item label="Siguente Mantenimiento x Fecha">{assignedService.nextServiceDate}</Descriptions.Item>
                                    <Descriptions.Item label="Siguente Mantenimiento x kilometros">{assignedService.nextServiceMileage}</Descriptions.Item>
                                </Descriptions>


                            </Panel>
                        )
                    })
                }
            </Collapse>
        );
    }
    return (
        <>
            <Popconfirm
                open={openConfirm}
                title="Plan no Guardado"
                description="Seleccionaste un plan pero no se guardo, deseas guardarlo antes de cerrar?"
                okText="Yes"
                cancelText="No"
                arrow={true}
                onConfirm={confirm}
                onCancel={closeDialogs} />

            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={saveAssignedPlan}
                okButtonProps={{ disabled: loading }}
                centered
                title={`Plan de Mantenimiento asignado`}
            >
                {!isAssignedPlan && <>
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Alert type="warning" message={Messages.vehicle.maintenance.notFound} />                        
                    </Space>
                    {drawServices()}
                </>}
                {isAssignedPlan && drawAssignedPlan()}
                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Modal>
        </>
    )
}
export default VehicleMaintenance;