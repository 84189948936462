import { Col, Modal, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import errorService from "../../services/error.service";
import VehicleService from "../../services/vehicle.service";

const style = {
    background: '#0092ff',
    padding: '8px 0'
};

const VehicleTires = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tires, setTires] = useState([]);
    const params = useParams();
    const [message, setMessage] = useState("");

    useEffect(() => {

        if (params.id) {
            VehicleService.getTires(params.id).then(
                (response) => {
                    setTires(response.data);
                    console.log("Bus tires", tires);                    
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                }
            );

        }
    }, []);

    const handleOk = () => {
        //TODO something
    }

    const closeModal = () => {

        navigate("/vehicles", { replace: true });
    }

    return (
        <Modal
            open={true}
            onCancel={closeModal}
            onOk={handleOk}
            okButtonProps={{ disabled: loading }}
            title={`Llantas del bus`}
        >
            <Row
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col className="gutter-row" span={6}>
                    <div style={style}>col-6</div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div style={style}>col-6</div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div style={style}>col-6</div>
                </Col>
                <Col className="gutter-row" span={6}>
                    <div style={style}>col-6</div>
                </Col>
            </Row>
        </Modal>
    )
}

export default VehicleTires;