import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';


import errorService from "../../../services/error.service";
import { Alert, DatePicker, Form, Input, Modal, Select, Steps } from "antd";
import Messages from "../../../common/messages";
import dayjs from "dayjs";
import IncidentService from "../../../services/incident.service";
import VehicleService from "../../../services/vehicle.service";
import eventBus from "../../../common/EventBus";
import UserService from "../../../services/user.service";
import Tracker from "../../../common/Constants";
import Upload from "antd/es/upload/Upload";
import TrackerMessages from "../../../common/messages";
import TextArea from "antd/es/input/TextArea";

const formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 15 } };

const WorkOrderForm = () => {


    let navigate = useNavigate();
    const location = useLocation();
    const toIncidentsLocation = location.state?.from?.pathname || "/incidents";

    const params = useParams();
    const [form] = Form.useForm();


    const [isOpen, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const [title, setTitle] = useState("Registro");
    const [editMode, setEditMode] = useState(false);

    const [vehicles, setVehicles] = useState([]);
    const [users, setUsers] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [imageToolTip, setImageToolTip] = useState(TrackerMessages.incident.form.image.tooltip.upload);
    const [statuses, setStatuses] = useState([{ label: "Nuevo", value: "new" }, { label: "en Curso", value: "working" }, { label: "Cerrado", value: "closed" }]);

    useEffect(() => {
        form.setFieldValue("reportedDate", dayjs());
        form.setFieldValue("dueDate", dayjs().add(1, 'week'));
        VehicleService.getAll()
            .then((response) => {
                setVehicles(response.data.map((vehicle) => {
                    vehicle.label = `${vehicle.code}[${vehicle.plate}]`;
                    vehicle.value = `${vehicle.id}`;
                    return vehicle;
                }))
            })
            .catch((error) => {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    eventBus.dispatch("logout");
                }
                setMessage(errorService.checkRequest(error));
            });

        UserService.getAllUsers()
            .then((response) => {
                setUsers(response.data.map((user) => {
                    user.label = `${user.name}-${user.lastname}`;
                    user.value = `${user.userId}`;
                    return user;
                }))
            })
            .catch((error) => {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    eventBus.dispatch("logout");
                }
                setMessage(errorService.checkRequest(error));
            });
        if (params.id) {
            setTitle('Edicion');
            setEditMode(true);
            IncidentService.getById(params.id).then(
                (response) => {
                    const incident = response.data;
                    form.setFieldValue("reportedDate", dayjs(incident.reportedDate));
                    form.setFieldValue("dueDate", dayjs(incident.dueDate));
                    form.setFieldValue("reporter", incident.reporter);
                    form.setFieldValue("vehicle", incident.vehicle);
                    form.setFieldValue("status", incident.status);
                    form.setFieldValue("description", incident.description);
                    setFileList([{
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: incident.imagePath,
                    }])
                    setImageToolTip(TrackerMessages.incident.form.image.tooltip.remove)

                }, (error) => {
                    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                        eventBus.dispatch("logout");
                    }
                    setMessage(errorService.checkRequest(error));
                }
            );
        } else {

        }
    }, []);


    const closeModal = () => {
        setOpen(false);
        navigate(toIncidentsLocation, { replace: true });
    }

    const handleSubmit = (incidentData) => {

        setMessage("");
        setLoading(true);

        if (editMode) {
            IncidentService.update(params.id, incidentData, fileList).then(
                (response) => {
                    setOpen(false);
                    navigate(toIncidentsLocation, { replace: true });
                },
                (error) => {
                    setMessage(errorService.checkRequest(error));
                    setLoading(false);
                }
            )
        } else {

            IncidentService.save(incidentData, fileList).then(
                (response) => {
                    setOpen(false);
                    navigate(toIncidentsLocation, { replace: true });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessage(_content);
                    setLoading(false);
                }
            )
        };

    }

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                values.reportedDate = dayjs(values.reportedDate).format("YYYY-MM-DD")
                values.dueDate = dayjs(values.dueDate).format("YYYY-MM-DD")
                handleSubmit(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt5M = file.size / 1024 / 1024 <= 5;
        if (!isLt5M) {
            message.error('The file size is big than allowed');
            return;
        }
        setFileList([file]);
    }
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const search = ()=>{

    }

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
            >
            </div>

            <Modal
                open={isOpen}
                onCancel={closeModal}
                onOk={handleOk}
                okButtonProps={{ disabled: loading }}
                centered
                title={`${title} de Repuestos`}
            >
                <Steps></Steps>
                <Form
                    {...formItemLayout}
                    size='medium'
                    form={form}
                    labelAlign='left'
                    validateMessages={Messages.common}
                >
                    <Form.Item label='Reportado' name='registryDate' rules={Tracker.Validation.requiredOnly}>
                        <DatePicker placeholder="2023-1-12" />
                    </Form.Item>
                    <Form.Item label='Fecha Ejecucion' name='executionDate' rules={Tracker.Validation.requiredOnly}>
                        <DatePicker placeholder="2023-1-15" />
                    </Form.Item>
                    <Form.Item label='Vehiculo' name={['vehicle', 'id']} rules={Tracker.Validation.requiredOnly}>
                        <Select options={vehicles} />
                    </Form.Item>                    
                    <Form.Item label='Solicitante' name={['reporter', 'userId']} rules={Tracker.Validation.requiredOnly}>
                        <Select options={users} />
                    </Form.Item>
                    <Form.Item label='Items' name={['item', 'itemId']} rules={Tracker.Validation.requiredOnly}>
                        <Select showSearch onSearch={search} options={users} />
                    </Form.Item>
                </Form>
                {message && (
                    <Alert
                        message="Request error"
                        description={message}
                        type="error"
                        closable
                    />
                )}
            </Modal>
        </>
    )
}
export default WorkOrderForm;