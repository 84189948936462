import axios from "axios";
import authHeader from "./auth-header";
import Tracker from "../common/Constants";

const API_URL = `${Tracker.backend.host}/chassis-model`;

const getAll = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

const getModel = (modelId) => {
  return axios.get(`${API_URL}/${modelId}`, { headers: authHeader() });
};

const save = (model) => {
  return axios.post(API_URL, model, { headers: authHeader() });
};


const deleteModel = (modelId) => {
  return axios.delete(`${API_URL}/${modelId}`, { headers: authHeader() });
};

const update = (modelId, model) => {
  return axios.put(`${API_URL}/${modelId}`, model, { headers: authHeader() });
};

const getItems = (modelId) => {
  return axios.get(`${API_URL}/${modelId}/items`, { headers: authHeader() });
};

const ChassisModelService = {
  getAll,
  save,
  update,
  deleteModel,
  getModel,
  getItems
};

export default ChassisModelService;
