import React, { useState, useEffect } from "react";

import ServiceTemplateService from "../../../../services/serviceTemplate.service";
import AuthService from "../../../../services/auth.service";
import EventBus from "../../../../common/EventBus";
import actionFactory from '../../../../common/ActionFactory'
import ButtonActionRow from "../../../../common/ButtonActionRow";
import { Space, Table } from "antd";


const Services = () => {
  const [content, setContent] = useState([]);
  const [tableActions, setTableActions] = useState([]);

  useEffect(() => {

    setTableActions(AuthService.getActionByGroup("Service", "table"));

    ServiceTemplateService.getAll().then(
      (response) => {
        console.log(response)
        setContent(response.data);
      },
      (error) => {
        console.log("Error", error)
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columns = [
    { title: 'Nombre', dataIndex: 'name', key: 'name' },
    { title: 'Recurrencia', dataIndex: 'type', key: 'type' },
    { title: 'Tiempo', dataIndex: 'timer', key: 'timer' },
    { title: 'Kilometros', dataIndex: 'mileage', key: 'mileage' },
    { title: 'Estado', dataIndex: 'active', key: 'active' },
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <Space size="middle">
          {
            tableActions.map((action, index) => {
              return actionFactory.getAction(action, { id: record.id })
            })
          }
        </Space>
      ),
    }
  ]

  const drawData = () => {
    if (!content || !Array.isArray(content) || content.length === 0) {
      <div>No content</div>
    } else {
      return content
    }
  }

  return (
    <>
      <ButtonActionRow group="Service" />

      <Table columns={columns} dataSource={drawData()} />
    </>
  );
};

export default Services;
